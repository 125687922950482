<div class="container center-align form-login">
  <br>
  <br>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <app-logo></app-logo>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <br>
      &nbsp;
      <br>
      &nbsp;
      <br>
      <app-spinner></app-spinner>
      <p>Por favor aguarde...</p>
      <p>Estamos validando seus dados, você será redirecionado em breve.</p>
      <br>
    </mat-card-content>
  </mat-card>
</div>
