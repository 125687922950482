import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {Alert} from '../types/alert';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public duration: number;
  private config: MatSnackBarConfig;

  constructor(
    private snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService,
  ) {
    this.duration = 12000;
    this.config = {
      duration: this.duration,
      horizontalPosition: 'right',
      verticalPosition: this.deviceService.isDesktop() ? 'top' : 'bottom',
    };
  }

  showError(message: string) {
    return this.open(Alert.ERROR, message);
  }

  showSuccess(message: string) {
    return this.open(Alert.SUCCESS, message);
  }

  showWarning(message: string) {
    return this.open(Alert.WARNING, message);
  }

  private open(tipo: string, message: string) {
    this.config.panelClass = [ `panel-${tipo}` ];
    return this.snackBar.open(message, '×', this.config);
  }

  close() {
    this.snackBar.dismiss();
  }
}
