<section>
  <div class="header-termo">
    <div class="wrapper-buttons-actions">
      <div>
        <button
          mat-icon-button
          matTooltip="Realizar Download"
          (click)="downloadPdf()"
        >
          <mat-icon>cloud_download</mat-icon>
        </button>

        <button
          fxHide.lt-md
          mat-icon-button
          matTooltip="Imprimir"
          (click)="imprimir()"
        >
          <mat-icon>print</mat-icon>
        </button>
      </div>

      <div>
        <button
          class="toogle-font-size"
          mat-icon-button
          matTooltip="Aumentar fonte"
          (click)="IncZoom()"
        >
          A+
        </button>
        <button
          class="toogle-font-size small"
          mat-icon-button
          matTooltip="Diminuir fonte"
          (click)="DecZoom()"
        >
          A-
        </button>
      </div>
    </div>
  </div>

  <pdf-viewer
    [src]="base64Code"
    [rotation]="0"
    [original-size]="false"
    [show-all]="true"
    [(page)]="page"
    [zoom]="zoom"
    style="width: 95vw; height: 80vh"
  >
  </pdf-viewer>
</section>
