import { User } from 'src/app/types/User';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Component, Inject } from '@angular/core';
import { DialogModalComponent } from '../../ui/dialog-modal/dialog-modal.component';
import { DOCUMENT } from '@angular/common';
import { TextoService } from 'src/app/services/texto.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: [ './menu-principal.component.scss' ],
})
export class MenuPrincipalComponent {

  currentUserName: string;
  modalDialogRef: MatDialogRef<DialogModalComponent>;
  private currentFontSize: number;
  currentUser: User;
  private classList: DOMTokenList;
  private htmlClassList = [
    '',
    'font-size--s',
    'font-size--m',
    'font-size--l',
    'font-size--g',
    'font-size--xg',
  ];
  isBeneficiario: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private textoService: TextoService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    public deviceService: DeviceDetectorService,
    private router: Router
  ) {

    this.authenticationService.currentUser$.subscribe(x => {
      this.currentUser = x;
    });
    this.classList = this.document.querySelector('html').classList;
    this.currentFontSize = Number(window.localStorage.getItem('fontSize')) || 0;
    this.setClass(this.htmlClassList[this.currentFontSize]);
  }

  diminuirFonte() {
    if (this.currentFontSize > 0) {
      this.setClass(this.htmlClassList[--this.currentFontSize]);
    }
  }

  aumentarFonte() {
    if (this.currentFontSize < this.htmlClassList.length - 1) {
      this.setClass(this.htmlClassList[++this.currentFontSize]);
    }
  }

  setClass(classe: string) {
    this.classList.value = '';
    window.localStorage.setItem('fontSize', this.currentFontSize.toString());
    if (!!classe) {
      this.classList.add(classe);
    }
  }

  openDialog() {
    this.modalDialogRef = this.dialog.open(DialogModalComponent, {
      data: {
        tipo: 'logout',
        titulo: '',
        mensagem: this.textoService.DesejaEncerrarSessao,
      },
    });
  }

}
