<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-12 col-lg-9">
      <div class="wrap d-md-flex" style="margin-bottom: 2%">
        <div
          class="px-5 py-5 mb-3 mb-md-0 d-flex logo col-md-5 col-lg-5 col-sm-12 pb-sm-5 pt-sm-5"
        >
          <img
            class="img w-100 align-middle p-lg-4 p-4"
            src="assets/imagens/logo-unimed-bancoexames.svg"
            alt="Logo Unimed Presidente Prudente"
          />
        </div>
        <div class="login-wrap p-md-5 col-md-7 col-lg-7 col-sm-12">
          <div class="d-flex row areatexto">
            <div class="col-12">
              <h2
                class="mb-2 d-flex justify-content-left pl-lg-5 pl-md-0 textoLogin"
              >
                Alterar Senha
              </h2>
            </div>
          </div>
          <form [formGroup]="formTrocarSenha">
            <div class="form-group mb-3">
              <div class="input-group mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    ><mat-icon aria-hidden="false">{{
                      lockIcon
                    }}</mat-icon></span
                  >
                </div>
                <input
                  #senha
                  matInput
                  placeholder="Senha atual"
                  type="password"
                  formControlName="senha"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="input-group mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    ><mat-icon aria-hidden="false">{{
                      lockIcon
                    }}</mat-icon></span
                  >
                </div>
                <input
                  #novaSenha
                  matInput
                  placeholder="Nova Senha"
                  type="password"
                  formControlName="novaSenha"
                  class="form-control"
                  minlength="6"
                  maxlength="16"
                  required
                />
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"
                  ><mat-icon aria-hidden="false">{{ lockIcon }}</mat-icon></span
                >
              </div>
              <input
                #confirmarSenha
                matInput
                placeholder="Confirmar Senha"
                type="password"
                formControlName="confirmarSenha"
                required
                class="form-control"
              />
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="form-control btn btn-outline-primary rounded submit px-3 btn btn-small waves-effect"
                (click)="trocarSenha()"
                [disabled]="disableBtn"
                color="primary"
              >
                <div class="button-alterar">
                  Confirmar Alteração
                  <mat-icon matSuffix>{{ lockIcon }}</mat-icon>
                </div>
              </button>
            </div>
            <span class="center">
              <mat-error *ngIf="formTrocarSenha.hasError('required', 'senha')">
                Por favor forneça sua senha atual
              </mat-error>
              <mat-error
                *ngIf="
                  formTrocarSenha.hasError('sameOld') && novaSenha.value !== ''
                "
              >
                Nova senha igual a antiga
              </mat-error>
              <mat-error
                *ngIf="
                  formTrocarSenha.hasError('notSame') &&
                  confirmarSenha.value !== ''
                "
              >
                Nova senha não é identica a confirmação
              </mat-error>
              <mat-error
                *ngIf="
                  (novaSenha.value?.length < 6 ||
                    novaSenha.value?.length > 16) &&
                  novaSenha.value.length > 0
                "
              >
                Sua senha deve ter no mínimo 6 caracteres e no máximo 16
              </mat-error>
            </span>
            <app-spinner></app-spinner>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
