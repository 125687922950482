<div class="container">
  <div class="row">
    <div class="m10 s12 offset-m1">
      <mat-card class="mat-elevation-z0 ultimos-atendimentos">
        <mat-card-header>
          <app-spinner *ngIf="sincronizando"></app-spinner>
          <mat-card-title *ngIf="!sincronizando" class="titulo">
            Últimos atendimentos</mat-card-title
          >
          <mat-icon
            class="sync"
            (click)="refresh()"
            [ngClass]="sincronizando ? 'spinning' : ''"
            >refresh
          </mat-icon>
        </mat-card-header>
        <br />
        <mat-card-content>
          <app-lista-ultimos-atendimentos
            [ultimosAtendimentos$]="ultimosAtendimentos$"
            *ngIf="!sincronizando && ultimosAtendimentos$"
          >
          </app-lista-ultimos-atendimentos>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
