<div class="row">
  <div
    class="col-sm-12 col-md-8 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start align-filter-mobile"
  >
    <div class="holder">
      <mat-radio-group aria-label="Filtrar periodo"  [(ngModel)]="dataIntervalo">
        <mat-radio-button
          [checked]="checkedDias === dias[1]"
          *ngFor="let dias of filtrarDias"
          [value]="dias[1]"

          (change)="applyDataFilter($event.value)"
          color="accent"
        >
          {{ dias[0] }}</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>
  <mat-form-field
    appearance="outline"
    class="col-sm-12 col-md justify-content-start"
  >
    <input
      #pesquisar
      matInput
      type="text"
      placeholder=""
      (keyup)="applyFilter(pesquisar.value)"
      autocomplete="off"
    />
    <button
      mat-button
      *ngIf="pesquisar.value.length > 0"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="pesquisar.value = ''; applyFilter('')"
    >
      <mat-icon style="color: #b1d34b">close</mat-icon>
    </button>
    <button
      mat-button
      *ngIf="pesquisar.value.length == 0"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="pesquisar.value = ''; applyFilter('')"
    >

      <mat-icon style="color: #b1d34b">search</mat-icon>
    </button>
  </mat-form-field>
</div>
<mat-table [dataSource]="ultimosAtendimentosDataSource" matSort>
  <!-- Nome Column -->
  <ng-container matColumnDef="nome">
    <mat-header-cell *matHeaderCellDef mat-sort-header
      >Beneficiario</mat-header-cell
    >
    <mat-cell
      *matCellDef="let item"
      [ngClass]="{'green': item.situacaoPep, 'red': !item. situacaoPep}"
    >
      <p class="nomeBeneficiario" matLine>{{ item.beneficiarioNome | uppercase}}</p>
      <p matLine fxHide.gt-xs>
        <small class="txtnomecampos">
          número do cartão
          <p class="txtresultcampos">
            {{
              item.carteirinha | mask: "0000.0000.000000.00-0"
            }}
          </p>
        </small>
      </p>
      <p matLine fxHide.gt-sm>
        <small class="txtnomecampos">
          data de nascimento
          <p class="txtresultcampos">
            {{
              item.dataNascimento | date: "dd/MM/yyyy"
            }}
          </p>
        </small>
      </p>
      <p matLine>

        <small *ngIf="item.nomeMae">
          <p class="txtnomecampos">nome da mãe</p>
          {{ item.nomeMae | lowercase}}
        </small>

        <small *ngIf="!item.nomeMae">
          <p class="txtnomecampos">nome da mãe</p>
          {{ 'Não Informado'}}
        </small>

      </p>
    </mat-cell>
  </ng-container>
  <!-- carteirinha Column -->
  <ng-container matColumnDef="carteirinha">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm
      >Cartão Unimed</mat-header-cell
    >
    <mat-cell *matCellDef="let item" fxHide.lt-sm>
      {{ item.beneficiarioCarteirinha }}
    </mat-cell>
  </ng-container>
  <!-- dataNascimento Column -->
  <ng-container matColumnDef="nascimento">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-md
      >Data Nascimento</mat-header-cell
    >
    <mat-cell *matCellDef="let item" fxHide.lt-md
      ><i
        >{{
          item.dataNascimento | date: "dd/MM/yyyy"
        }}
      </i></mat-cell
    >
  </ng-container>
  <!-- data autorização Column -->
  <ng-container matColumnDef="dataAutorizacao">
    <mat-header-cell *matHeaderCellDef mat-sort-header
      >Data atendimento</mat-header-cell
    >
    <mat-cell *matCellDef="let item">
      <span>
        <i fxHide.lt-sm> {{ item.dataAtendimento | date: "dd/MM/yyyy" }}</i>
        <i fxHide.gt-xs>
          {{ item.dataAtendimento | date: "dd/MM/yyyy" }}
        </i>

        <mat-chip-list fxHide.gt-sm >
          <mat-chip
            style="color: green"
            (click)="navegarDetalhes(item)"
          >
            <img
              src="assets/imagens/icons/page-ultimos-atendimentos/search.svg"
              alt="resultado laudos"
            />

          </mat-chip>
        </mat-chip-list>

      </span>
    </mat-cell>
  </ng-container>
  <!-- data PEP Column -->
  <ng-container matColumnDef="pep">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      fxHide.lt-md
    ></mat-header-cell>
    <mat-cell *matCellDef="let item" fxHide.lt-md matTooltip="Visualizar Situação">
      <mat-chip-list>
        <mat-chip
          (click)="navegarDetalhes(item)"
        >
          <img
            src="assets/imagens/icons/page-ultimos-atendimentos/search.svg"
            alt="resultado laudos"
          />
        </mat-chip>

      </mat-chip-list>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    *matRowDef="let item; columns: displayedColumns"
    (click)="navegarDetalhes(item)"
  ></mat-row>
</mat-table>
<div class="no-data" *ngIf="ultimosAtendimentosDataSource?.data?.length > 0 == false">
  <p class="text-center p-2" *ngIf="checkedDias == 1">Nenhum atendimento encontrado com a data de hoje.</p>
  <p class="text-center p-2" *ngIf="checkedDias > 1">Nenhum atendimento encontrado nos últimos {{checkedDias}} dias.</p>
</div>
<mat-paginator
  [pageIndex]="pageIndex"
  [length]="pageLength"
  [pageSize]="15"
  [pageSizeOptions]="[15, 25, 35, 50]"
  (page)="getPaginatorEvent($event)"
  showFirstLastButtons
>
</mat-paginator>
