<header *ngIf="navbarService.visible">
  <app-menu-principal></app-menu-principal>
</header>
<main [ngClass]="helperService.classePagina">
  <router-outlet></router-outlet>
</main>
<footer *ngIf="router.url !== '/aceite' && router.url !== '/visualizar'">
  <app-footer></app-footer>
</footer>
<app-menu-fab *ngIf="navbarService.visible"></app-menu-fab>
