import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageAceiteConfidencialidadeComponent } from "src/app/components/pages/page-aceite-confidencialidade/page-aceite-confidencialidade.component";
import { PageErro404Component } from "src/app/components/pages/page-erro404/page-erro404.component";
import { PageLoginComponent } from "src/app/components/pages/page-login/page-login.component";
import { PageTrocarSenhaComponent } from "src/app/components/pages/page-trocar-senha/page-trocar-senha.component";
import { PageUploadTermosComponent } from "src/app/components/pages/page-upload-termos/page-upload-termos.component";
import { AuthGuard } from "src/app/guards/auth.guard";
import { PageAjudaComponent } from "src/app/components/pages/page-ajuda/page-ajuda.component";
import { PageAtendimentoComponent } from "../../components/pages/page-atendimento/page-atendimento.component";
import { PageDetalhesComponent } from "../../components/pages/page-detalhes/page-detalhes.component";
import { PageAutenticadorComponent } from "src/app/components/pages/page-autenticador/page-autenticador.component";
import { VisualizarPdfComponent } from "src/app/components/ui/visualizar-pdf/visualizar-pdf.component";

const routes: Routes = [
  {
    path: "login",
    component: PageLoginComponent,
  },
  {
    path: "autenticador/:id",
    component: PageAutenticadorComponent,
  },
  {
    path: "autenticador/:id/:id2", // Link autenticação rápida APP Mobile.
    component: PageAutenticadorComponent,
  },
  {
    path: "pesquisar",
    redirectTo: "/atendimento",
  },
  {
    path: "atendimento",
    component: PageAtendimentoComponent,
    canActivate: [AuthGuard],
    data: { roles: [2] },
  },
  {
    path: "aceite",
    component: PageAceiteConfidencialidadeComponent,
    canActivate: [AuthGuard],
    data: { roles: [1, 2, 3] },
  },
  {
    path: "enviar-termo/:id",
    component: PageUploadTermosComponent,
    canActivate: [AuthGuard],
    data: { roles: [1, 2, 3] },
  },
  {
    path: "ajuda",
    component: PageAjudaComponent,
    canActivate: [AuthGuard],
    data: { roles: [1, 2] },
  },
  {
    path: "detalhes",
    component: PageDetalhesComponent,
    canActivate: [AuthGuard],
    data: { roles: [1, 2, 3, 4, 5, 6] },
  },
  {
    path: 'visualizar',
    component: VisualizarPdfComponent,
    data: {roles: 1}
  },
  {
    path: "trocar-senha",
    component: PageTrocarSenhaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: PageLoginComponent,
    canActivate: [ AuthGuard ],
    // data: {roles: [2]}
  },
  {
    path: "**",
    component: PageErro404Component,
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
