<mat-toolbar *ngIf="currentUser">
  <mat-toolbar-row class="mat-elevation-z2" style="height: 70px;">
    <app-logo></app-logo>
    <span class="menu-spacer"></span>
    <div>
      <button
        class="step-2"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Bem vindo"
        matTooltip="Menu principal"
      >
        <span mat-line class="username"
          >Bem vindo(a) {{ currentUser.nome }}
        </span>
        <mat-icon>reorder</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item class="d-md-none" aria-label="Usuário">
        <mat-icon>person_outline</mat-icon>
        <span> {{ currentUser.nome }}</span>
      </button>
      <button
        mat-menu-item
        [routerLink]="['/trocar-senha']"
        matTooltip="Trocar senha"
      >
        <mat-icon>security</mat-icon>
        <span>Trocar senha</span>
      </button>
      <button
        *ngIf="currentUser.tipoUsuarioId == 2"
        mat-menu-item
        [routerLink]="['/atendimento']"
        matTooltip="Ultimos Atendimentos"
      >
        <mat-icon>supervised_user_circle</mat-icon>
        <span>Ultimos Atendimentos</span>
      </button>
      <button mat-menu-item [routerLink]="['/ajuda']" matTooltip="Ajuda">
        <mat-icon>import_contacts</mat-icon>
        <span>Ajuda</span>
      </button>
      <button
        mat-menu-item
        class="warn"
        (click)="openDialog()"
        aria-label="Sair"
        matTooltip="Sair - Encerrar sessão"
      >
        <mat-icon>exit_to_app</mat-icon>
        <span>Sair</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="router.url !== '/visualizar'">
    <span class="step-1">
      <button
        class="toogle-font-size"
        mat-icon-button
        (click)="diminuirFonte()"
        matTooltip="Diminuir fonte"
      >
        A-
      </button>
      <button
        class="toogle-font-size"
        mat-icon-button
        (click)="aumentarFonte()"
        matTooltip="Aumentar fonte"
      >
        A+
      </button>
    </span>
  </mat-toolbar-row>
</mat-toolbar>
