import {Role} from './Role';
import {Beneficiario} from './Beneficiario';
import {TipoLogin} from './TipoLogin';

export class User {
  id: number;
  handle?: string;
  token: string;
  nome?: string;
  login: string;
  role: Role;
  tipoUsuarioId: number;
  Cooperado?: CooperadoDados;
  Beneficiario?: Beneficiario;
  alterarSenha?: boolean;
  mkmama?: string;
  situacaoPep: boolean;

  constructor(public json: IUsuario) {
    this.id = this.json?.usuario?.id || 0;
    this.token = this.json?.token || '';
    this.nome = this.limpaNome(this.json?.usuario.nome || '');
    this.login = this.json?.usuario.login || '';
    this.alterarSenha = this.json?.alterarSenha || false;
    this.role = this.json.areaRestrita?.area?.value === TipoLogin.coop.key ? Role.Doctor : this.json.role || Role.User;
    this.tipoUsuarioId = this.json?.usuario.tipoUsuarioId;
    this.situacaoPep = this.json?.usuario.situacaoPep;
    delete this.json;
  }

  limpaNome = (nome: string): string => nome.toLowerCase().replace('coop -', 'Dr.(a)').replace(/(^|\s)\S/g, (l: any) => l.toUpperCase());
}


export interface IUsuario {
  logado?: boolean;
  alterarSenha?: boolean;
  token?: string;
  usuario?: IUser;
  areaRestrita?: AreaRestrita;
  usuarioPadrao?: UsuarioPadrao;
  role?: Role;
  tipoUsuarioId?: number;
}

export interface AreaRestrita {
  area?: Area;
  handle?: number;
  codigo?: string;
  nome?: string;
  nomeAbreviado?: string;
}

export interface Area {
  name?: string;
  description?: string;
  value?: number;
}

export interface IUser {
  login?: string;
  nome?: string;
  nomeAbreviado?: string;
  email?: string;
  id?: number;
  tipoUsuarioId?: number;
  situacaoPep: boolean;
}

export interface UsuarioPadrao {
  handle?: number;
}

export interface CooperadoDados {
  handle: number;
  nome?: string;
}
