<mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
  <!-- imprimir step -->
  <mat-step state="print">
    <ng-template matStepLabel>Imprimir termo</ng-template>

    <mat-card class="center mat-elevation-z0">
      <div class="head-icon">
        <i class="material-icons">print</i>
      </div>
      <p>{{ textoService.CliqueParaImprimirTermo }}</p>
      <mat-card-actions>
        <button class="u-step-1" mat-raised-button color="primary" aria-label="Aceitar Termo" [routerLink]="['/aceite']">Aceitar Termo <i class="material-icons">print</i></button>
      </mat-card-actions>
    </mat-card>

    <div class="center">
      <button *ngIf="authenticationService.currentUserValue.role !== 'Auditor'" mat-button [routerLink]="['/detalhes']" aria-label="Voltar">Voltar</button>
      <button *ngIf="authenticationService.currentUserValue.role === 'Auditor'" mat-button [routerLink]="['/detalhes-auditoria']" aria-label="Voltar">Voltar</button>
      <button mat-button aria-label="Avançar">Avançar</button>
    </div>
  </mat-step>
  <!-- upload step -->
  <mat-step state="upload">
    <form [formGroup]="secondFormGroup" class="center">
      <ng-template matStepLabel>Enviar termo</ng-template>
      <mat-card class="mat-elevation-z0">
        <div class="head-icon">
          <i class="material-icons">add_to_photos</i>
        </div>
        <p>{{ textoService.CliqueParaEnviarTermo }}</p>
        <mat-card-actions>
          <input formControlName="imgUpload" required #imgInput hidden="true" type="file" onclick="this.value=null"  accept="image/*" capture="camera">
          <button class="u-step-2" mat-raised-button color="primary" (click)="imgInput.click()"  aria-label="Selecionar arquivo"> Selecionar arquivo <i class="material-icons">image</i>
          </button> &nbsp; <button class="u-step-3" mat-raised-button [disabled]="!image" color="primary"  aria-label="Enviar termo"> Enviar termo <i class="material-icons">cloud_upload</i>
          </button>
        </mat-card-actions>
        <app-spinner></app-spinner>
        <img *ngIf="image" [src]="image" class="full-width">
      </mat-card>
      <div>
        <button mat-button matStepperPrevious  aria-label="Voltar">Voltar</button>
      </div>
    </form>
  </mat-step>
  <!-- ultimo step -->
  <mat-step state="sucesso">
    <ng-template matStepLabel>Sucesso</ng-template>
    <mat-card class="mat-elevation-z0 center">
      <div class="head-icon">
        <i class="material-icons">cloud_done</i>
      </div>
      <p> {{ textoService.TermoEnviadoComSucesso }} </p>
      <mat-card-actions>
        <button [routerLink]="['/detalhes']" mat-raised-button color="primary"  aria-label="Retornar ao perfil"> Retornar ao perfil <i class="material-icons">person_outline</i></button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
  <!-- custom icons -->
  <ng-template matStepperIcon="sucesso">
    <mat-icon>cloud_done</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="upload">
    <mat-icon>cloud_upload</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="print">
    <mat-icon>print</mat-icon>
  </ng-template>
</mat-horizontal-stepper>
