import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HelperService } from "./helper.service";
import { NavbarService } from "./navbar.service";
import { MessageService } from "./message.service";
import { SpinnerService } from "./spinner.service";
import { User } from "../types/User";
import { AuthParams } from "../types/AuthParams";
import { Role } from "../types/Role";
import { BeneficiarioService } from "./beneficiario.service";

export enum AuthPath { // Caminhos da API do autenticador - AUTHPATH
  AUDITORIA = "login/auditoria",
  LOGIN = "login",
  USUARIO = "login/usuario",
  USUARIOHANDLE = "login/usuarioHandle",
  CPF = "login/usuarioCpf",
  TROCARSENHA = "senha/alterar",
  MKDATA = "loginMKData/usuarioLaudos",
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private currentUserSubject$: BehaviorSubject<User>;
  public currentUser$: Observable<User>;

  handleBenef: string;

  constructor(
    private http: HttpClient,
    public helperService: HelperService,
    public router: Router,
    private cookieService: CookieService,
    private navbarService: NavbarService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private beneficiarioService: BeneficiarioService
  ) {
    this.currentUserSubject$ = new BehaviorSubject<User>(
      JSON.parse(window.localStorage.getItem("currentUser"))
    );
    this.currentUser$ = this.currentUserSubject$.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject$.value;
  }

  setCurrentUser(user: User) {
    window.localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUserSubject$.next(user);
  }

  authenticate(u: any): any {
    this.destroy();
    this.setCurrentUser(new User(u));
    if (this.currentUserValue.tipoUsuarioId === 1) {
      this.beneficiarioService.consultaDadosBeneficiarioToken().subscribe((x) => {
        u = {
          ...u,
          usuario: {
            ...u.usuario,
            situacaoPep: x.situacaoPep
          }
        }

        this.setCurrentUser(new User(u))
        this.successRedirect();

        localStorage.setItem('handleBenef', x.id.toString());
      })
    } else {
      this.successRedirect();
    }
  }

  private successRedirect() {
    const auth = this.updateCurrentUser(this.currentUserValue);
    auth
      .subscribe(() => {
        this.helperService.setClassePagina("");
        this.navbarService.show();
        this.messageService.close();
      })
      .add(() => this.router.navigate(["/atendimento"]));
  }

  updateCurrentUser(user: User) {
    this.destroy();
    return forkJoin([
      of(this.setCurrentUser(user)),
      of(
        !!this.cookieService.check("access_token") || this.setCookie(user.token)
      ),
    ]);
  }

  private setCookie(token: string) {
    const payload = JSON.parse(atob(token?.split(".")[1]));
    if (!!payload) {
      this.cookieService.set(
        "access_token",
        token,
        new Date(payload.exp * 1000),
        null,
        null,
        null,
        null
      );
    }
  }

  // setters

  /**
   * Faz a autenticação através do login e senhas do usuário
   * @param login
   * @param {string} senha
   * @param {number} TipoAcesso
   * @return {Observable<any>}
   */
  login(login: string, senha: string, TipoAcesso: number): Observable<any> {
    return this.http
      .post<any>(`${environment.apiBase}${AuthPath.LOGIN}`, {
        login,
        senha,
        TipoAcesso,
      })
      .pipe(map((x) => x.data));
  }

  loginBenEncoded(handle: string): Observable<any> {
    return this.http
      .post<any>(`${environment.apiBase}login/autenticador`, {
        handle,
      })
      .pipe(map((x) => x.data));
  }

  loginAuditor(usuarioId: string, tipoAcesso: number): Observable<any> {
    return this.http
      .post<any>(`${environment.apiBase}login/autenticador`, {
        usuarioId,
        tipoAcesso,
      })
      .pipe(map((x) => x.data));
  }

  loginBenefMobile(carteirinha: string, tipoAcesso: number): Observable<any> {
    return this.http
      .post<any>(`${environment.apiBase}login/autenticador`, {
        carteirinha,
        tipoAcesso,
      })
      .pipe(map((x) => x.data));
  }

  destroy(): Observable<any> {
    const allCookies: {} = this.cookieService.getAll();
    this.spinnerService.hideSpinner();
    return forkJoin([
      of(
        Object.keys(allCookies).map((x: string) => this.cookieService.delete(x))
      ),
      of(this.setCurrentUser(null)),
      of(window.localStorage.clear()),
      of(window.sessionStorage.clear()),
    ]);
  }

  logout(): Observable<any> {
    return this.destroy();
  }

  trocarSenha(
    senhaAtual: string,
    senhaNova: string,
    senhaConfirmacao: string
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiBase}${AuthPath.TROCARSENHA}`,
      {
        senhaAtual,
        senhaNova,
        senhaConfirmacao,
      }
    );
  }

  autenticacaoRapida(usuarioCompleto: any, auth: AuthParams): boolean {
    this.destroy();
    forkJoin([
      of(this.setCurrentUser(new User(usuarioCompleto))),
    ]).subscribe(() => {
      this.spinnerService.hideSpinner();
      //localStorage.removeItem('handleBenef');
      localStorage.removeItem('autorizacaoAuditor');

      //Login Area Restrita Beneficiarios
      if (usuarioCompleto.usuario.tipoUsuarioId === 1) {
        this.beneficiarioService.consultaDadosBeneficiarioToken().subscribe((x) => {
          usuarioCompleto = {
            ...usuarioCompleto,
            usuario: {
              ...usuarioCompleto.usuario,
              situacaoPep: x.situacaoPep
            }
          }

          this.setCurrentUser(new User(usuarioCompleto))
          this.successRedirect();

          localStorage.setItem('handleBenef', x.id.toString());
          this.router.navigate([`/detalhes`]);
        })
      }
      //Login Area Restrita Cooperados
      else if (usuarioCompleto.usuario.tipoUsuarioId === 2) {
        this.router.navigate([`/atendimento`]);
        return true;
      }
      // Login Auditor Benner
      else if (usuarioCompleto.usuario.tipoUsuarioId === 3) {
        localStorage.setItem('autorizacaoAuditor', auth.autorizacao?.toString());
        localStorage.setItem('handleBenef', auth.beneficiario);
        this.router.navigate([`/detalhes`]);
        return true;
      }
      // Login MKData
      else if ([4, 5, 6].includes(usuarioCompleto.usuario.tipoUsuarioId)) {
        this.beneficiarioService.consultaDadosBeneficiarioCarterinha(HelperService.padLeft(auth.beneficiario, '0', 17)).subscribe((x) => {
          localStorage.setItem('autorizacaoAuditor', auth.autorizacao?.toString());
          localStorage.setItem('handleBenef', x.id.toString());
          this.router.navigate([`/detalhes`]);
        });
      }

      return true;
    });

    return false;
  }
}
