import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { BeneficiarioService } from 'src/app/services/beneficiario.service';
import { MessageService } from 'src/app/services/message.service';
import { TextoService } from 'src/app/services/texto.service';
import { Beneficiario } from 'src/app/types/Beneficiario';
import { ProgressSpinnerDialogComponent } from '../../ui/progress-spinner-dialog/progress-spinner-dialog.component';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-page-upload-termos',
  templateUrl: './page-upload-termos.component.html',
  styleUrls: [ './page-upload-termos.component.scss' ],
  host: { class: 'full-width' },
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class PageUploadTermosComponent implements OnInit {

  param: string;
  handle: string;
  finalizado = false;
  secondFormGroup: FormGroup;
  image: string | ArrayBuffer;
  beneficiario: Beneficiario;
  progressSpinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent>;
  steps: any;

  constructor(
    public textoService: TextoService,
    public authenticationService: AuthenticationService,
  ) {

  }

  ngOnInit() {

  }

  }
