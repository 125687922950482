<div class="container">
  <div class="row">
    <div class="col m10 s12 offset-m1 texto">
      <div class="container-termo">
        <h1 class="titulo">
          {{
            authenticationService.currentUserValue.tipoUsuarioId == 1
              ? "PRONTUÁRIO ELETRÔNICO DO PACIENTE"
              : "COMPROMISSO DE CONFIDENCIALIDADE E SIGILO"
          }}
        </h1>

        <div class="container-termo">
          <header>
            <img src="assets/imagens/logounimed-ans.svg" />
            <p class="subtitulo">
              TERMO DE CONSENTIMENTO PARA UTILIZAÇÃO DAS INFORMAÇÕES DOS
              BENEFICIÁRIOS NO PEP (PRONTUÁRIO ELETRÔNICO DO PACIENTE)
            </p>
          </header>

          <section class="dadosBeneficiario">
            <p>
              Eu, {{ authenticationService.currentUserValue.nome }}, CPF nº
              <span *ngIf="cpf"> {{ cpf }}</span
              ><span *ngIf="!cpf">Não Informado</span>, nascido em
              {{ dataNascimento }}, declaro por meio deste termo, de forma livre
              e consentida, que:
            </p>
          </section>

          <div
            class="termsx"
            [innerHTML]="
              authenticationService.currentUserValue.tipoUsuarioId == 1
                ? termoDeConfidencialidadeBenef
                : termoDeConfidencialidade
            "
          ></div>

          <section class="assinatura">
            <p class="name-assinante">
              {{ authenticationService.currentUserValue.nome }} (Assinante) -
              {{ cpf }}
            </p>

            <p class="dataAssinada">em {{ hoje }} - {{ horario }}</p>

            <p class="tipo-assinatura"><strong>Tipo:</strong>Assinatura Eletrônica</p>

            <p class="identification"><strong>Indentificação:</strong><span>Por Usuario:    {{ authenticationService.currentUserValue.login }}</span></p>

          </section>

          <section class="campo-data">
            <p>Presidente Prudente, {{ hoje }}</p>
          </section>

          <div class="footer">
            <img
              src="assets/imagens/ans-aceite.svg"
              matTooltip="Unimed Presidente Prudente - ANS nº - 31.579-6"
            />
            <span
              >Avenida Coronel José Soares Marcondes, 3355 - Jardim
              Bongiovani</span
            >
            <span
              >CEP 19050-230 - Presidente Prudente/SP - CNPJ:
              44.863.959/0001-26</span
            >
            <span>SAC 0800 770 6855 - Fone (18) 2101-8340</span>
            <span>www.unimedprudente.com.br</span>
            <span>E-mail: cadastro@unimedprudente.com.br</span>
          </div>
        </div>
      </div>
      <div style="text-align: center">
        <mat-checkbox [(ngModel)]="aceiteCondicoes"
          >Li e concordo com os termos apresentados acima</mat-checkbox
        >
      </div>
      <div class="aceite-buttons">
        <button
          mat-raised-button
          color="warn"
          (click)="sair()"
          matTooltip="Sair"
        >
          Sair
        </button>
        <button
          *ngIf="!mostrarSpin"
          mat-raised-button
          color="primary"
          [disabled]="!aceiteCondicoes"
          (click)="salvar()"
          matTooltip="Continuar"
        >
          Continuar <i class="material-icons">arrow_forward</i>
        </button>
        <div
          *ngIf="mostrarSpin"
          mat-raised-button
          class="container-button-spinner"
        >
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
