import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-progress-spinner-dialog",
  templateUrl: "./progress-spinner-dialog.component.html",
})
export class ProgressSpinnerDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
