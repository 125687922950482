import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Observable, of } from "rxjs";

import { Beneficiario } from "src/app/types/Beneficiario";
import { BeneficiarioService } from "src/app/services/beneficiario.service";
import { EventEmitterService } from "../../../services/event-emitter.service";
import { HelperService } from "../../../services/helper.service";
import { SpinnerService } from "src/app/services/spinner.service";

@Component({
  selector: "app-atendimento",
  host: { class: "full-width container-buscador" },
  templateUrl: "./page-atendimento.component.html",
  styleUrls: ["./page-atendimento.component.scss"],
})
export class PageAtendimentoComponent implements OnInit {
  @ViewChild("beneficiario") inputEl: ElementRef;
  ultimosAtendimentos$: Observable<Beneficiario[]>;
  sincronizando = false;
  initialSinc = true;
  dias = !!window.sessionStorage.getItem("filtroDias")
    ? Number(window.sessionStorage.getItem("filtroDias"))
    : 1;

  constructor(
    private beneficiarioService: BeneficiarioService,
    private spinnerService: SpinnerService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    // lista atendimento
    this.syncAtendimento();
    window.sessionStorage.setItem("trocaDias", "true");
    this.helperService.setClassePagina("");
    EventEmitterService.get("QuantidadesDeDiasAnteriores").subscribe((x) => {
      let troca = window.sessionStorage.getItem("trocaDias");
      if (troca === "true") {
        troca = "false";
        this.dias = x;
        this.alterData();
      }
    });
    // lista atendimento
    this.spinnerService.showSpinner();
  }

  private getBeneficiariosEmAtendimento = (): Observable<Beneficiario[]> =>
    this.beneficiarioService.consultaAutorizacaoEmAtendimento(this.dias);

  syncAtendimento() {
    if (!this.sincronizando && this.initialSinc) {
      this.spinnerService.showSpinner();
      this.ultimosAtendimentos$ = of([]);
      this.sincronizando = true;
      this.getBeneficiariosEmAtendimento().subscribe((x) => {
        this.ultimosAtendimentos$ = of(x);
        this.sincronizando = false;
        this.initialSinc = false
        this.spinnerService.hideSpinner();
      });
    }
  }

  refresh() {
    this.spinnerService.showSpinner();
    this.getBeneficiariosEmAtendimento().subscribe((x) => {
      this.ultimosAtendimentos$ = of(x);
      this.sincronizando = false;
      this.initialSinc = false
      this.spinnerService.hideSpinner();
    });
  }

  alterData() {
    if (!this.sincronizando && !this.initialSinc) {
      this.spinnerService.showSpinner();
      this.ultimosAtendimentos$ = of([]);
      this.sincronizando = true;
      this.getBeneficiariosEmAtendimento().subscribe((x) => {
        this.ultimosAtendimentos$ = of(x);
        this.sincronizando = false;
        this.initialSinc = false
        this.spinnerService.hideSpinner();
      });
    }
  }

}
