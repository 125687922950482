import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { SpinnerService } from "src/app/services/spinner.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MessageService } from "src/app/services/message.service";
import { TextoService } from "src/app/services/texto.service";
import { TipoLogin } from "../../../types/TipoLogin";
import { User } from "../../../types/User";
import { ErrorStateMatcher } from "@angular/material/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-page-trocar-senha",
  templateUrl: "./page-trocar-senha.component.html",
  styleUrls: ["./page-trocar-senha.component.scss"],
})
export class PageTrocarSenhaComponent implements OnInit {
  @ViewChild("senha") inputEl: ElementRef;
  formTrocarSenha: FormGroup;
  lockIcon = "lock_outline";
  disableBtn = true;
  errors: any;

  private currentUser: User;

  constructor(
    public textoService: TextoService,
    public builder: FormBuilder,
    private spinnerService: SpinnerService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router
  ) {
    this.authenticationService.currentUser$.subscribe(
      (x) => (this.currentUser = x)
    );
    this.formTrocarSenha = this.builder.group(
      {
        senha: [null, Validators.required],
        novaSenha: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(16),
            Validators.minLength(6),
          ]),
        ],
        confirmarSenha: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(16),
            Validators.minLength(6),
          ]),
        ],
      },
      { validator: PageTrocarSenhaComponent.checkPasswords }
    );
  }

  static checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const novo = group.controls.novaSenha.value;
    const confirmar = group.controls.confirmarSenha.value;
    const antigo = group.controls.senha.value;

    return antigo === novo
      ? { sameOld: true }
      : novo !== confirmar
      ? { notSame: true }
      : null;
  }

  ngOnInit() {
    setTimeout(() => this.inputEl.nativeElement.focus(), 0);
    this.formTrocarSenha.valueChanges.subscribe(() => {
      if (this.formTrocarSenha.valid) {
        this.lockIcon = "lock_open";
        this.disableBtn = false;
      } else {
        this.lockIcon = "lock_outline";
        this.disableBtn = true;
      }
    });
  }

  trocarSenha() {
    this.spinnerService.showSpinner();
    if (this.formTrocarSenha.invalid) {
      return;
    }

    this.authenticationService
      .trocarSenha(
        this.formTrocarSenha.controls.senha.value,
        this.formTrocarSenha.controls.novaSenha.value,
        this.formTrocarSenha.controls.confirmarSenha.value
      )
      .subscribe(
        (u: any) => {
          this.spinnerService.hideSpinner();
          this.messageService.showSuccess(
            this.textoService.SenhaModificadaComSucesso
          );
          this.formTrocarSenha.reset();
          Object.keys(this.formTrocarSenha.controls).map((x) =>
            this.formTrocarSenha.controls[x].setErrors(null)
          );
          this.authenticationService.updateCurrentUser({
            ...this.currentUser,
            ...{ alterarSenha: false },
          });
          this.router.navigate(['/atendimento'])
        },
        (erro: any) =>
          this.messageService.showError(
            erro.hasOwnProperty("message")
              ? erro.message
              : this.textoService.ErroAoExecutarSolicitacao + erro.name
          )
      )
      .add(() => this.spinnerService.hideSpinner());
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
