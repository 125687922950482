import { map, take } from "rxjs/operators";

import { Autorizacao } from "../types/Autorizacao";
import { Beneficiario } from "../types/Beneficiario";
import { DadosBeneficiario } from "./../types/DadosBeneficiarios";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BeneficiarioService {
  constructor(private httpClient: HttpClient) {}

  consultaResultados(idBeneficiario: string): Observable<any[]> {
    return this.httpClient.get(environment.apiBase + `Resultado?idBeneficiario=${idBeneficiario}`).pipe(take(1), map((x: any) => x.data));
  }

  consultaAutorizacaoEmAtendimento(
    qtdeDias: number = 30
  ): Observable<Beneficiario[]> {
    return this.httpClient
      .get<Beneficiario[]>(
        environment.apiBase + `Atendimento?qtdeDias=${qtdeDias}`
      )
      .pipe(
        take(1),
        map((x: any) => x.data)
      );
  }

  // Puxar informações do beneficiários - NÃO VEM LAUDOS
  consultaDadosBeneficiario(handle: string): Observable<DadosBeneficiario> {
    return this.httpClient
      .get<DadosBeneficiario>(environment.apiBase + `beneficiario/${handle}`)
      .pipe(
        map((x: any) => x.data)
      );
  }


  consultaDadosBeneficiarioCarterinha(carteirinha: string): Observable<DadosBeneficiario> {
    return this.httpClient
      .get<DadosBeneficiario>(environment.apiBase + `beneficiario?carteirinha=${carteirinha}`)
      .pipe(
        map((x: any) => x.data)
      );
  }


  consultaDadosBeneficiarioToken(): Observable<DadosBeneficiario> {
    return this.httpClient
      .get<DadosBeneficiario>(environment.apiBase + `beneficiario`)
      .pipe(
        take(1),
        map((x: any) => x.data)
      )
  }

  getExamesBeneficiario(handle: any): Observable<Autorizacao[]> {
    return this.httpClient
      .get<Autorizacao[]>(
        environment.apiBase + `Autorizacao?idbeneficiario=${handle}`
      )
      .pipe(
        take(1),
        map((x: any) => x.data)
      );
  }

  // NAO TIRAR VERIFICAR
  postTermoBeneficiario(payload: object): Observable<any> {
    return this.httpClient.post(
      environment.apiBase + `api/termos/salvar`,
      payload
    );
  }

  // NAO TIRAR VERIFICAR
  postParticipaProgramaPreventiva(carteirinha: string): Observable<any> {
    return this.httpClient
      .post(
        environment.apiBase + `api/beneficiario/participaprogramapreventiva`,
        {
          CodigoBeneficiario: carteirinha,
        }
      )
      .pipe(map((x: any) => x.data));
  }
}
