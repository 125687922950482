import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {User} from './User';
import {Beneficiario} from './Beneficiario';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ProgressSpinnerDialogComponent} from '../components/ui/progress-spinner-dialog/progress-spinner-dialog.component';

@Injectable()
export abstract class BaseBeneficiario {
  progressSpinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent>;
  param: string;

  protected constructor(
    protected route: ActivatedRoute,
    protected auth: AuthenticationService,
    protected dialog: MatDialog,
  ) {
    this.param = localStorage.getItem("handleBenef");
  }

  getIdFromParam(): string {
    const _id = localStorage.getItem("handleBenef");
    return isNaN(Number(_id)) ? JSON.parse(atob(_id)).beneficiario.toString() : _id;
  }

  showProgressSpinner() {
    this.progressSpinnerDialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'progress-spinner',
      disableClose: true,
    });
  }
}
