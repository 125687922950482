import { EventEmitterService } from "./../../../services/event-emitter.service";
import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DOCUMENT } from "@angular/common";
import { TextoService } from "src/app/services/texto.service";
import { HelperService } from "src/app/services/helper.service";
import { MessageService } from "src/app/services/message.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { RemoteIPService } from "src/app/services/remote.ip.service";
import { map } from "rxjs/operators";
import { Beneficiario } from "../../../types/Beneficiario";
import { User } from "src/app/types/User";
import { PdfMakeService } from "src/app/services/pdfmake.service";
import { Observable, of } from "rxjs";
import { DadosBeneficiario } from "src/app/types/DadosBeneficiarios";
import { BeneficiarioService } from "src/app/services/beneficiario.service";
import moment from "moment";

@Component({
  selector: "app-page-confidencialidade",
  templateUrl: "./page-aceite-confidencialidade.component.html",
  styleUrls: ["./page-aceite-confidencialidade.component.scss"],
  host: { class: "full-width" },
})
export class PageAceiteConfidencialidadeComponent implements OnInit {
  termoDeConfidencialidade: string;
  termoDeConfidencialidadeBenef: string;
  aceiteCondicoes: boolean;
  ip: any;
  beneficiarios: Beneficiario;
  beneficiario: DadosBeneficiario;

  currentUser: User;
  mostrarSpin: boolean = false;

  hoje: string;
  horario: string;
  timeStamp: Date;

  cpf: string;
  dataNascimento: string;

  BeneficiarioSelecionado: Observable<DadosBeneficiario>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    public authenticationService: AuthenticationService,
    private textoService: TextoService,
    private helperService: HelperService,
    private spinnerService: SpinnerService,
    private remoteIpService: RemoteIPService,
    private pdfMakeService: PdfMakeService,
    private beneficiarioService: BeneficiarioService
  ) {
    this.authenticationService.currentUser$.subscribe((x: User) => {
      if (!!x) {
        this.beneficiarios = x.Beneficiario;
        this.currentUser = x;
        if (this.currentUser.situacaoPep !== undefined) {
          if(this.currentUser.situacaoPep && this.currentUser.tipoUsuarioId === 1){
            this.router.navigate(["/detalhes"]);
          }
          if(this.currentUser.situacaoPep && this.currentUser.tipoUsuarioId === 2){
            this.router.navigate(["/atendimento"]);
          }
        }
      }
    });
    EventEmitterService.get("AlteraLoading").subscribe((x: any) => {
      this.mostrarSpin = x;
    });

    moment.locale("pt-br");
    this.hoje = moment().format("LL");
    this.horario = moment().format("LT");
  }

  ngOnInit() {
    this.termoDeConfidencialidade = this.textoService.TermoDeConfidencialidade;
    this.termoDeConfidencialidadeBenef =
      this.textoService.TermoDeConfidencialidadeBeneficiario;

    if (this.authenticationService.currentUserValue.tipoUsuarioId === 1) {
      this.carregaBeneficiario().subscribe((x) => {
        this.beneficiario = x;
        this.cpf = this.helperService.formataCPF(this.beneficiario.cpf);
        this.dataNascimento = moment(x.dataNascimento).format("DD/MM/YYYY");
      });
    }
  }

  carregaBeneficiario(): Observable<DadosBeneficiario> {
    return this.beneficiarioService.consultaDadosBeneficiarioToken();
  }

  sair() {
    this.authenticationService
      .logout()
      .subscribe((x) => this.router.navigate(["/login"]));
  }

  salvar() {
    this.mostrarSpin = true;
    this.spinnerService.showSpinner();
    const subtitulo = (this.document.querySelector(".subtitulo") as HTMLElement)
      .innerText;
    const dadosBenef = (
      this.document.querySelector(".dadosBeneficiario") as HTMLElement
    ).innerText;
    const termo = (this.document.querySelector(".termsx") as HTMLElement)
      .innerText;

    const assinante = (
      this.document.querySelector(".name-assinante") as HTMLElement
    ).innerText;
    const dataAssinada = (this.document.querySelector(".dataAssinada") as HTMLElement).innerText;

    const typeassinatura = (
      this.document.querySelector(".tipo-assinatura") as HTMLElement
    ).innerText;
    const identification = (
      this.document.querySelector(".identification") as HTMLElement
    ).innerText;

    const dataExecucao = (
      this.document.querySelector(".campo-data") as HTMLElement
    ).innerText;
    const footer = (this.document.querySelector(".footer") as HTMLElement)
      .innerText;

    if (!this.beneficiario) {
      this.currentUser.situacaoPep = true;
      const auth = this.authenticationService.updateCurrentUser(
        this.currentUser
      );

      return auth.subscribe().add(() => this.router.navigate(["/atendimento"]));
    } else if (this.beneficiario) {
      this.currentUser.situacaoPep = true;
      const auth = this.authenticationService.updateCurrentUser(
        this.currentUser
      );
      this.enviaPDF(
        auth,
        subtitulo,
        termo,
        assinante,
        dataAssinada,
        typeassinatura,
        identification,
        footer,
        dadosBenef,
        dataExecucao
      );
      return auth.subscribe().add(() => this.router.navigate(["/detalhes"]));
    }
  }

  enviaPDF(
    auth: any,
    subtitulo: string,
    termo: string,
    assinante: string,
    dataAssinada: string,
    typeassinatura: string,
    identification: string,
    footer: string,
    dadosBenef: string,
    dataExecucao: string
  ) {
    this.remoteIpService
      .getIp()
      .pipe(map((x) => x.ip))
      .subscribe((x: string) => (this.ip = x))
      .add(() => {
        this.pdfMakeService.generatePdf(
          subtitulo,
          termo,
          assinante,
          dataAssinada,
          typeassinatura,
          identification,
          footer,
          this.ip,
          dadosBenef,
          dataExecucao
        );

        this.spinnerService.hideSpinner();
      });
  }
}
