import { saveAs } from "file-saver";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { EventEmitterService } from "src/app/services/event-emitter.service";
import printJS from "print-js";

@Component({
  selector: "app-visualizar-pdf",
  templateUrl: "./visualizar-pdf.component.html",
  styleUrls: ["./visualizar-pdf.component.scss"],
})
export class VisualizarPdfComponent implements OnInit {
  zoom: number = 0.7;
  incDecZoom: number = 0.2;

  @HostListener('window:resize')
  getScreenSizeResize() {
        this.SetMediaDefaulZoom();
  }

  SetMediaDefaulZoom(){
    this.zoom = window.innerWidth < 1000 ? 1.0 : 0.7;
  } 
  
  IncZoom(){
    this.Zoom(this.incDecZoom)
  }
  
  DecZoom(){
    this.Zoom(this.incDecZoom*-1)
  }
  
  Zoom(amount: number) {
    this.zoom += amount;
  }

  page: number = 1;
  pdfSrc: string;
  base64Code: string;

  @Input() base64: Observable<string>;

  constructor() {
    EventEmitterService.get("Base64TermoVisualizar").subscribe((x: any) => {
      this.base64Code = x;
    });
  }

  imprimir() {
    var base64data = this.base64Code.replace(
      "data:application/pdf;base64,",
      ""
    );
    printJS({ printable: base64data, type: "pdf", base64: true });
  }

  downloadPdf() {
    saveAs(this.base64Code, "relatorio");
  }

  ngOnInit(): void {
    this.SetMediaDefaulZoom();
  }
}
