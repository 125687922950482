<section *ngIf="!usuario" class="ftco-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-9">
        <div class="wrap d-md-flex">
          <div
            class="px-5 py-5 mb-3 mb-md-0 d-flex logo col-md-5 col-lg-5 col-sm-12 pb-sm-5 pt-sm-5"
          >
            <img
              class="img w-100 align-middle p-lg-4 p-4"
              src="assets/imagens/logo-banco-de-exames.svg"
              alt="Logo Unimed Presidente Prudente"
            />
          </div>
          <div class="login-wrap p-md-5 col-md-7 col-lg-7 col-sm-12">
            <div class="d-flex row areatexto">
              <div class="col-12">
                <h2
                  class="mb-2 d-flex justify-content-left pl-lg-5 pl-md-0 textoLogin"
                >
                  Login
                </h2>
              </div>
              <!-- <div class="justify-content-left d-flex">
                <span class="textobanco">Acesse o <b>Banco de Exames</b> da Unimed Prudente</span>
              </div> -->
            </div>
            <form
              action="#"
              class="signin-form px-lg-5 px-sm-0 px-md-0"
              [formGroup]="loginForm"
            >
              <div class="form-group mb-3">
                <div class="input-group mt-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"
                      ><mat-icon aria-hidden="false"
                        >person_outline</mat-icon
                      ></span
                    >
                  </div>
                  <input
                    #username
                    formControlName="username"
                    type="text"
                    class="form-control"
                    placeholder="Seu login"
                    aria-label="username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="input-group mt-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"
                      ><mat-icon aria-hidden="false"
                        >lock_outline</mat-icon
                      ></span
                    >
                  </div>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    placeholder="Senha"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01"
                    ><mat-icon>badge</mat-icon></label
                  >
                </div>
                <select class="custom-select" formControlName="tipo" required>
                  <option *ngFor="let tipo of tipoLogin" [value]="tipo.value">
                    {{ tipo.viewValue }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="form-control btn btn-outline-primary rounded submit px-3"
                  (click)="loginSubmit()"
                  [disabled]="disableBtn"
                >
                  Efetuar login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
