import { Observable, throwError } from "rxjs";
import { catchError, map, take } from "rxjs/operators";

import { Autorizacao } from "src/app/types/Autorizacao";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "../types/User";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AutorizacaoService {
  currentUser: User;

  constructor(private httpClient: HttpClient) {}

  consultaAutorizacao(handle: string): Observable<Autorizacao[]> {
    return this.httpClient
      .get<Autorizacao[]>(
        environment.apiBase + `Autorizacao?idBeneficiario=${handle}`
      )
      .pipe(
        take(1),
        map((x: any) => x.data)
      );
  }

  consultaAutorizacaoAuditor(autorizacao: string): Observable<Autorizacao[]> {
    return this.httpClient
      .get<Autorizacao[]>(
        environment.apiBase + `Autorizacao?autorizacao=${autorizacao}`
      )
      .pipe(
        take(1),
        map((x: any) => x.data)
      );
  }

  consultaAutorizacaoCarterinha(
    carteirinha: string
  ): Observable<Autorizacao[]> {
    return this.httpClient
      .get<Autorizacao[]>(
        environment.apiBase + `Autorizacao?carteirinha=${carteirinha}`
      )
      .pipe(
        take(1),
        map((x: any) => x.data)
      );
  }

  consultarBeneficiarioId(beneficiarioId: string): Observable<Autorizacao[]> {
    return this.httpClient
      .get<Autorizacao[]>(
        environment.apiBase + `Autorizacao?IdBeneficiario=${beneficiarioId}`
      )
      .pipe(
        map((x: any) => x.data)
      );
  };

  consultaAutorizacaoToken(): Observable<Autorizacao[]> {
    return this.httpClient
      .get<Autorizacao[]>(environment.apiBase + `Autorizacao`)
      .pipe(
        take(1),
        map((x: any) => x.data)
      );
  }

  consultarAutorizacaoEmAtendimento(qtdeDias: number = 30): Observable<any> {
    return this.httpClient.get(
      environment.apiBase + `atendimento?qtdedias=${qtdeDias}`
    );
  }

  postPdfResultado(payload: any): Observable<any> {
    return this.httpClient.post(
      environment.apiBase + "Resultado/pdf",
      payload,
      { responseType: "blob" }
    );
  }

  postImprimeTermo(payload: any): Observable<any> {
    return this.httpClient
      .post(environment.apiBase + "api/termos/imprimir", payload, {
        responseType: "blob",
      })
      .pipe(
        catchError((e) =>
          throwError({
            message: e.message
              .replace("Http failure response for ", "Erro: ")
              .replace(": 400 OK", ""),
            status: e.status,
          })
        )
      );
  }
}
