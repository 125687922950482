<section>
  <div class="container">
    <div class="row">
      <div class="col s12">
        <div class="container-404">
          <div class="card-content-404 center">
            <div class="row card-container-column">
              <h1 class="col-lg">Erro 404</h1>
              <p class="col-sm">
                Desculpe, a página que você buscou está indisponível
              </p>
              <a class="col-lg-9 col-sm" [routerLink]="['/atendimento']">
                Me leve para a Página Inicial
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
