import { Avatar } from "./../../../types/Avatar";
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { BeneficiarioService } from "../../../services/beneficiario.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
  avatarLink = `assets/imagens/avatar.svg`;
  @Input() foto?: Observable<Avatar>;

  constructor() {}

  ngOnInit() {
    if (this.foto?.subscribe != null) {
      this.foto.subscribe((x) => {
        if (!!x?.foto && !!x.formato) {
          this.avatarLink = `data:image/${x?.formato};base64,${x.foto}`.replace(
            /(\r\n\t|\n|\r\t)/gm,
            ""
          );
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    var foto = changes.foto.currentValue;
    if (!!foto?.foto && !!foto.formato)
      this.avatarLink =
        `data:image/${foto.formato};base64,${foto.foto}`.replace(
          /(\r\n\t|\n|\r\t)/gm,
          ""
        );
      else {
        this.avatarLink = `assets/imagens/avatar.svg`;
      }
  }
}
