import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {environment} from 'src/environments/environment';
import {Error} from '../types/Error';
import {TextoService} from '../services/texto.service';
import {Router} from '@angular/router';
import {SpinnerService} from '../services/spinner.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private spinnerService: SpinnerService,
    private textoService: TextoService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((errorType: Error) => {
      if ([401, 403].indexOf(errorType.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        this.authenticationService.logout().subscribe(x => {
          this.router.navigate(['/login']);
        });

        return throwError({});
      }

      if (errorType.status === 0) {
        return throwError(errorType);
      }

      const erro = {
        status: errorType.status,
        message: (errorType.name === 'HttpErrorResponse') ? errorType.message : this.textoService.ServidorIndisponivel,
      };


      if (!environment.production) {
        console.log('Error Interceptor: ', errorType);
      }

      if (errorType instanceof HttpErrorResponse && errorType.error instanceof Blob) {
        return new Promise<any>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e: Event) => {
            try {
              const errMsg = JSON.parse((<any>e.target).result);
              const erroTemp = [...errMsg.errors].pop();
              reject(new HttpErrorResponse({
                error: errMsg,
                headers: errorType.headers,
                status: errorType.status,
                statusText: errorType.statusText,
                url: erroTemp.value,
              }));
            } catch (e) {
              reject(errorType);
            }
          };
          reader.onerror = () => reject(errorType);
          reader.readAsText(errorType.error);
        });
      }

      if (errorType?.error) {
        const _error = [...errorType.error?.errors]?.pop();
        erro.message = _error.value !== undefined ? _error.value : _error.message;
      }

      return throwError(erro);
    }));
  }
}
