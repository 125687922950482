import { ActivatedRoute, Router } from "@angular/router";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

import { AuthenticationService } from "src/app/services/authentication.service";
import { Autorizacao } from "src/app/types/Autorizacao";
import { AutorizacaoService } from "src/app/services/autorizacao.service";
import { BaseBeneficiario } from "../../../types/BaseBeneficiario";
import { BeneficiarioService } from "src/app/services/beneficiario.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { DialogModalComponent } from "../../ui/dialog-modal/dialog-modal.component";
import { Dias } from "../../../types/Dias";
import { EventEmitterService } from "../../../services/event-emitter.service";
import { MessageService } from "src/app/services/message.service";
import { Observable, Subscription } from "rxjs";
import { ProgressSpinnerDialogComponent } from "../../ui/progress-spinner-dialog/progress-spinner-dialog.component";
import { RemoteIPService } from "../../../services/remote.ip.service";
import { ResultadoProps } from "src/app/types/Resultado";
import { TextoService } from "src/app/services/texto.service";
import { map } from "rxjs/operators";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { Sort } from "@angular/material/sort";

export interface Dado {
  autorizacao: string;
  dataAutorizacao: string;
  exame: string;
  exameDescricao: string;
  prestador: string;
}

@Component({
  selector: "app-lista-exames-beneficiario",
  templateUrl: "./lista-exames-beneficiario.component.html",
  styleUrls: ["./lista-exames-beneficiario.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ListaExamesBeneficiarioComponent
  extends BaseBeneficiario
  implements OnInit
{
  @ViewChild("tabLaudosDisponiveis")
  tabLaudosDisponiveis: ElementRef<HTMLLIElement>;
  progressSpinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent>;
  listaDeLaudos$: Observable<Autorizacao[]>;

  dataIntervalo: string;
  autorizacao: any;
  handleDependente: string;
  listaDeResultados: ResultadoProps[];
  listaDeResultadosFiltrado: ResultadoProps[];
  ExamesDataSource: MatTableDataSource<Autorizacao>;
  listaResultadoHistoricoExames: Autorizacao[];
  listaResultadoHistoricoExamesFiltrado: Autorizacao[];

  subsVar: Subscription;
  filtro: string = "";
  base64: string;
  filtroLaudos: string;
  private ip: string;

  tabAtivaIndex = 0;

  colunas: string[] = [
    "autorizacao",
    "dataAutorizacao",
    "exame",
    "exameDescricao",
    "prestador",
  ];

  constructor(
    private router: Router,
    private textoService: TextoService,
    public dialog: MatDialog,
    protected route: ActivatedRoute,
    private autorizacaoService: AutorizacaoService,
    private messageService: MessageService,
    protected auth: AuthenticationService,
    private dialogService: MatDialog,
    private remoteIPService: RemoteIPService,
    private beneficiarioService: BeneficiarioService,
    public deviceService: DeviceDetectorService
  ) {
    super(route, auth, dialog);

    this.dataIntervalo = Dias.Todos;
    this.filtroLaudos = "Todos exames";

    this.remoteIPService
      .getIp()
      .pipe(map((x) => x.ip))
      .subscribe((x: string) => (this.ip = x));
  }

  ngOnInit() {
    this.subsVar = EventEmitterService.get(
      "ExamesPorUserHandleEmitter"
    ).subscribe((x) => {
      localStorage.setItem("handleBenef", x);


      this.obterResultado(x);
      this.obterHistoricoExame(x);
      this.filtrarArray();
    });

    this.param = localStorage.getItem("handleBenef");

    this.obterResultado(this.param);
    this.obterHistoricoExame(this.param);
  }

  ngOnDestroy() {
    if (this.subsVar) {
      this.subsVar.unsubscribe();
    }
  }

  obterHistoricoExame(beneficiarioId: string) {
    this.autorizacaoService
      .consultarBeneficiarioId(beneficiarioId)
      .subscribe((x) => {
        this.listaResultadoHistoricoExames = x;
        this.listaResultadoHistoricoExamesFiltrado = x;
        this.filtrarArray();
      });
  }

  obterResultado(id: string) {
    this.showProgressSpinner();

    return this.beneficiarioService.consultaResultados(id).subscribe((x) => {
      this.listaDeResultados = x;
      this.progressSpinnerDialogRef.close();
      this.filtrarArray();
    });
  }

  obterListaDeLaudos(): Observable<Autorizacao[]> {
    if (this.auth.currentUserValue.tipoUsuarioId === 3) {
      this.autorizacao = window.sessionStorage.getItem("autorizacaoAuditor");
      return this.autorizacaoService.consultaAutorizacaoAuditor(
        this.autorizacao
      );
    }

    if ([4, 5, 6].includes(this.auth.currentUserValue.tipoUsuarioId)) {
      return this.autorizacaoService.consultaAutorizacaoCarterinha(this.param);
    }
  }

  applyFilter(filterValue: any): void {
    this.filtro = filterValue;
    this.filtrarArray();
  }

  filtrarArray(): void {
    const filtro = this.filtro?.toLowerCase()?.trim();

    this.listaDeResultadosFiltrado = this.listaDeResultados;
    this.listaResultadoHistoricoExamesFiltrado =
      this.listaResultadoHistoricoExames;

    if (filtro === "") return;

    this.listaDeResultadosFiltrado = this.listaDeResultadosFiltrado.filter(
      (item) => {
        return (
          item.prestadorNome.toLowerCase().includes(filtro) ||
          item.prestadorCodigo.toLowerCase().includes(filtro) ||
          item.exames.some(
            (exame) =>
              exame.eventoCodigo.toLowerCase().includes(filtro) ||
              exame.eventoNome.toLowerCase().includes(filtro)
          )
        );
      }
    );

    this.listaDeResultadosFiltrado = this.listaDeResultadosFiltrado.map(
      (item) => {
        return {
          ...item,
          exames: item.exames.filter(
            (exame) =>
              exame.eventoCodigo.toLowerCase().includes(filtro) ||
              exame.eventoNome.toLowerCase().includes(filtro)
          ),
        };
      }
    );

    this.listaDeResultadosFiltrado = this.listaDeResultadosFiltrado.filter(
      (item) => item.exames.length > 0
    );

    this.listaResultadoHistoricoExamesFiltrado =
      this.listaResultadoHistoricoExamesFiltrado.filter(
        (item) =>
          item.executorNome.toLowerCase().includes(filtro) ||
          item.executorCodigo.toString().toLowerCase().includes(filtro) ||
          item.numeroAutorizacao.toString().toLowerCase().includes(filtro) ||
          item.eventoDescricao.toLowerCase().includes(filtro) ||
          item.eventoEstrutura.toLowerCase().includes(filtro)
      );
  }

  abrirLaudo(laudoId: string) {
    this.showProgressSpinner();
    this.autorizacaoService
      .postPdfResultado({
        ip: this.ip,
        documentoId: laudoId,
        Usuario: this.auth.currentUserValue.id,
      })
      .subscribe(
        (res) => {
          var blob = new Blob([res], { type: "application/pdf" });
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            var base64string = reader.result;
            this.base64 = base64string.toString();
            EventEmitterService.get("Base64TermoVisualizar").emit(this.base64);
          };
          this.router.navigate(["visualizar"]);
        },
        (erro) => {
          this.progressSpinnerDialogRef.close();
          this.messageService.showWarning(
            erro.message || this.textoService.ErroGenerico
          );
        },
        () => this.progressSpinnerDialogRef.close()
      );
  }

  openDialog(mensagem: string) {
    this.dialogService.open(DialogModalComponent, {
      data: {
        tipo: "printTermo",
        titulo: "",
        mensagem,
        payload: {
          handle: this.param,
        },
      },
    });
  }

  showProgressSpinner() {
    this.progressSpinnerDialogRef = this.dialog.open(
      ProgressSpinnerDialogComponent,
      {
        panelClass: "progress-spinner",
        disableClose: true,
      }
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    const data = this.listaResultadoHistoricoExamesFiltrado.slice();
    if (!sort.active || sort.direction === "") {
      this.listaResultadoHistoricoExamesFiltrado = data;
      return;
    }

    this.listaResultadoHistoricoExamesFiltrado = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "autorizacao":
          return this.compare(a.numeroAutorizacao, b.numeroAutorizacao, isAsc);
        case "dataExecucao":
          return this.compare(
            a.dataHoraExecucao.toString(),
            b.dataHoraExecucao.toString(),
            isAsc
          );
        case "dataAutorizacao":
          return this.compare(
            a.dataHoraExecucao.toString(),
            b.dataHoraExecucao.toString(),
            isAsc
          );
        case "exame":
          return this.compare(a.eventoEstrutura, b.eventoEstrutura, isAsc);
        case "exameDescricao":
          return this.compare(a.eventoDescricao, b.eventoDescricao, isAsc);
        case "prestador":
          return this.compare(a.executorNome, b.executorNome, isAsc);
        default:
          return 0;
      }
    });
  }

  filtrarLaudosDisponiveis(evento: Autorizacao) {
    this.applyFilter(evento.eventoDescricao);
    this.tabLaudosDisponiveis.nativeElement.click();

    this.tabLaudosDisponiveis.nativeElement.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }
}
