<div
  *ngIf="
    !!authenticationService.currentUserValue &&
    authenticationService.currentUserValue.role !== 'Auditor' &&
    router.url !== '/atendimento'
  "
  class="fab-container-sub step-6"
>
  <button
    mat-mini-fab
    class="fab-toggler"
    color="warn"
    (click)="voltarPagina()"
    aria-label="Voltar"
    matTooltip="Voltar"
  >
    <i class="material-icons">keyboard_backspace</i>
  </button>
</div>
