<span class="close-dialog" (click)="dialogRef.close()">&times;</span>
<h1 mat-dialog-title class="center">{{ data.titulo }}</h1>
<mat-dialog-content class="center">{{ data.mensagem }}</mat-dialog-content>
<mat-dialog-actions *ngIf="data.tipo">
  <button
    mat-stroked-button
    *ngIf="data.tipo === 'carregarTermo'"
    class="mx-auto"
  >
    Imprimir termo de aceite <i class="material-icons">print</i>
  </button>
  <button
    mat-raised-button
    color="primary"
    *ngIf="data.tipo === 'padrao'"
    (click)="dialogRef.close()"
  >
    Fechar
  </button>
  <button
    matTooltip="Sim, Sair do Sistema"
    mat-raised-button
    color="warn"
    *ngIf="data.tipo === 'logout'"
    (click)="logout()"
  >
    Sim
  </button>
  <button
    matTooltip="Não, Continuar Utilizando o Sistema"
    mat-raised-button
    color="primary"
    *ngIf="data.tipo === 'logout'"
    (click)="dialogRef.close()"
  >
    Não
  </button>
  <button
    mat-raised-button
    color="warn"
    *ngIf="data.tipo === 'printTermo'"
    (click)="printTermo(data.payload.handle)"
  >
    <i class="material-icons">print</i> Imprimir termo
  </button>
</mat-dialog-actions>
