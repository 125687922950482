import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, take, delay, retryWhen } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class RemoteIPService {

  constructor(private httpClient: HttpClient) {
  }

  getIp(): Observable<any> {
    return this.httpClient
      .get(environment.apiRemoteIP)
      .pipe(
        retryWhen(errors => errors.pipe(delay(2000), take(15))),
        catchError(() => EMPTY),
      );
  }
}
