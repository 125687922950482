<mat-toolbar *ngIf="!auth.currentUserValue">
  <mat-toolbar-row class="mat-elevation-z2">
    <app-logo></app-logo>
    <span class="menu-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>
<br />
&nbsp;
<br />

<div class="container">
  <mat-card>
    <h1 class="titulo mt-0">
      <mat-icon>import_contacts</mat-icon>
      Documentos de ajuda
    </h1>

    <br />

    <div class="grid">
      <div *ngIf="linkVideo" class="videoWrapper">
        <iframe
          width="560"
          height="349"
          [src]="linkVideo"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>

      <div>
        <span>
          <h1 class="titulo">Downloads</h1>

          <a
            *ngIf="linkDocumento"
            mat-raised-button
            [href]="linkDocumento"
            color="primary"
            target="_blank"
          >
            <span>
              Baixar o Manual do Usuário
              <span class="hidden-sm"> - Banco de exames</span>
            </span>
            <mat-icon>file_download</mat-icon>
          </a>
          <br />
          &nbsp;
          <br />
        </span>

        <h1 class="titulo">Contatos</h1>
        <p class="normal">
          Em caso de duvidas disponibilizamos os seguintes canais de
          comunicação:
        </p>
        <ul class="list-unstyled">
          <li>
            <strong
              >Tel: <a href="tel:+55182101-8387">(18) 2101-8387</a></strong
            >
          </li>
          <li>
            <strong
              >WhatsApp:
              <a
                href="https://wa.me/5518997824964?text=Olá%20Unimed%20preciso%20de%20ajuda%20com%20o%20portal%20de%20laudos"
                >(18) 99782-4964</a
              ></strong
            >
          </li>
          <li>
            <strong
              >Email:
              <a href="mailto:laudos@unimedprudente.com.br"
                >laudos@unimedprudente.com.br</a
              ></strong
            >
          </li>
        </ul>
      </div>
    </div>
    <br />
    &nbsp;
    <br />
  </mat-card>
</div>
<br />
&nbsp;
<br />
<div class="container">
  <mat-card>
    <h2 class="titulo">Banco de Exames</h2>
    <mat-card-content>
      <p>
        O Banco de Exames integra o Prontuário Eletrônico e ajuda a mapear a sua
        saúde, tornando seu atendimento mais eficiente e seguro.
      </p>
      <p>
        Somente terão acesso às suas informações, os médicos com acesso restrito
        (login e senhas pessoais e intransferíveis) durante atendimentos em
        consulta ou pronto-socorro.
      </p>
      <br />
      &nbsp;
      <br />
      <img
        mat-card-image
        src="assets/imagens/o_que_eh_pep.jpg"
        alt="Saiba mais sobre o Prontuário eletrônico"
      />
      <ol>
        <li>
          <h3>
            <mat-chip-list>
              <mat-chip>01</mat-chip>
            </mat-chip-list>
            Quando foram criados os sistemas de prontuários eletrônicos
          </h3>
          <p>
            No Brasil, a regulamentação do prontuário eletrônico é de 2002, e o
            Sistema Único de Saúde já utiliza o sistema desde 2013. segundo o
            Ministério da Saúde, o prontuário eletrônico já é usado em
            aproximadamente 17.431 UBSs, em 3.070 municípios. Hoje, se você for
            atendido na rede pública de saúde, o seu atendimento já será
            registrado em um sistema de prontuário eletrônico, e os dados serão
            compartilhados pelos profissionais do local.
          </p>
        </li>
        <li>
          <h3>
            <mat-chip-list>
              <mat-chip>02</mat-chip>
            </mat-chip-list>
            O que é o prontuário eletrônico do paciente - PEP?
          </h3>
          <p>
            A resolução 1638/2002 do Conselho Federal de Medicina (CFM) diz:
            "documento único constituído de um conjunto de informações, sinais e
            imagens registrados, gerado a partir de fatos, acontecimentos e
            situações sobre a saúde do paciente e a assist~encia a ele prestada,
            de caráter lega, sigiloso e científico, que possibilita a
            comunicação entre membros da equipe multiprofissional e a
            continuidade de assistência prestada ao indivíduo".
          </p>
        </li>
        <li>
          <h3>
            <mat-chip-list>
              <mat-chip>03</mat-chip>
            </mat-chip-list>
            O que consta no prontuário?
          </h3>
          <p>
            Identificação do paciente, o histórico de dados e de sintomas
            descritos pelo paciente, laudos e exames, hipóteses diagnosticas,
            diagnostico definitivos e tratamentos efetuados.
          </p>
        </li>
        <li>
          <h3>
            <mat-chip-list>
              <mat-chip>04</mat-chip>
            </mat-chip-list>
            Quais as vantagens para os beneficiários?
          </h3>
          <p>
            Com acesso ao histórico de dados, o médico que estiver atendendo
            terá maiores recursos para prestar um atendimento resolutivo e ágil.
            Desta forma, poderiam ser evitados possíveis retornos, assim como a
            realização de exames desnecessarios que expõem os pacientes a
            radiação ou procedimentos invasivos. Além disso, o Beneficiário
            poderá ter redução de custos com coparticipação de exames, uma vez
            que o laudo poderá ser reaproveitado por vários médicos e
            consequentemente poderá haver uma redução no reajuste anual da
            mensalidade.
          </p>
        </li>
        <li>
          <h3>
            <mat-chip-list>
              <mat-chip>05</mat-chip>
            </mat-chip-list>
            Como posso ter certeza da segurança das informações acessadas nos
            prontuários?
          </h3>
          <p>
            O sistema de informática vai garantir que o prontuário só será
            liberado a partir do momento que o beneficiário estiver em consult,
            seja no consultório, nos serviços de pronto-atendimento ou
            emergências. Cada médico terá login exclusivo, permitindo a
            rastreabilidade de acesso às informações contidas nos prontuários,
            garantindo o sigilo e segurança ao paciente.
          </p>
        </li>
        <li>
          <h3>
            <mat-chip-list>
              <mat-chip>06</mat-chip>
            </mat-chip-list>
            Se o beneficiário não assinou o termo de consentimento e quer aderir
            ao prontuário eletrônico, como proceder?
          </h3>
          <p>
            Compareça a qualquer ponto de atendimento próprio da Unimed, ou se
            informe via central de atendimento pelo telefone (18)2101-8311,
            sobre outros locais onde o termo de consentimento estárá disponível.
            Lembrnado que, para os beneficiários maiores de 18 anos o termo
            deverá ser assinado de forma individual e para os menores de 18 anos
            deverá ser assinado pelo responsável legal.
          </p>
        </li>
        <li>
          <h3>
            <mat-chip-list>
              <mat-chip>07</mat-chip>
            </mat-chip-list>
            Posso revogar o termo de consentimento assinado autorizando a
            inclusão de dados no prontuário eletrônico?
          </h3>
          <p>
            A qualquer momento o beneficiário poderá comparecer pessoalmente ao
            setor de Atendimento ao Beneficiário da Unimed, localizado na Rua
            Walter Faria Motta, 310 Jardim Bongiovani - Presidente Prudente - SP
            e solicitar a revogação de consentimento assinado.
          </p>
        </li>
      </ol>
    </mat-card-content>
  </mat-card>
</div>
