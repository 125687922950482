import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthenticationService } from "../../../services/authentication.service";
import { Beneficiario } from "../../../types/Beneficiario";

@Component({
  selector: "app-page-ajuda",
  templateUrl: "./page-ajuda.component.html",
  styleUrls: ["./page-ajuda.component.scss"],
})
export class PageAjudaComponent implements OnInit {
  private video: any;
  private documento: any;
  linkVideo: SafeResourceUrl;
  linkDocumento: SafeResourceUrl;
  beneficiario: Beneficiario;
  tipoUser: string;

  constructor(
    private _sanitizer: DomSanitizer,
    public auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.video = [
      ...environment.youtubeVideos.filter(
        (v) => v.titulo === `manual_${this.tipoUser}`
      ),
    ].pop();
    this.documento = [
      ...environment.documentos.filter(
        (d) => d.titulo === `manual_${this.tipoUser}`
      ),
    ].pop();
    this.linkVideo = this._sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${this.video.id}`
    );
    this.linkDocumento = this._sanitizer.bypassSecurityTrustResourceUrl(
      `./assets/documentos/${this.documento.nome}`
    );
  }
}
