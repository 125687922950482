import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { HelperService } from "src/app/services/helper.service";
import { MessageService } from "src/app/services/message.service";
import { NavbarService } from "src/app/services/navbar.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { TextoService } from "src/app/services/texto.service";
import { AuthParams } from "src/app/types/AuthParams";
import { Role } from "src/app/types/Role";

@Component({
  selector: "app-page-autenticador",
  templateUrl: "./page-autenticador.component.html",
  styleUrls: ["./page-autenticador.component.scss"],
})
export class PageAutenticadorComponent implements OnInit {
  private auth: AuthParams;
  param: string;
  param2: string;
  autenticador: boolean;

  constructor(
    private helperService: HelperService,
    private spinnerService: SpinnerService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private router: Router,
    private messageService: MessageService,
    private textoService: TextoService,
  ) {
    this.param = this.route.snapshot.paramMap.get("id");
    this.param2 = this.route.snapshot.paramMap.get("id2"); // puxar ID da carteirinha na requisição do app mobile para consulta.
    if (!!this.authService.currentUserValue) {
      this.navbarService.hide();
    }
  }


  ngOnInit() {
    setTimeout(() => {
      this.helperService.setClassePagina("login-page");
      this.spinnerService.showSpinner();
      this.authService.destroy();
    }, 0);

    try {
      if (!!this.param2) {
        this.authService.loginBenefMobile(this.param2, 1).subscribe(
          (usuarioCompleto: any) => {
            usuarioCompleto = {
              ...usuarioCompleto,
              ...{
                usuarioId: usuarioCompleto.usuario.id,
              },
            };

            this.authService.authenticate(usuarioCompleto); // Verificação do Aceite

          },
          (erro) => {
            this.messageService.showError(erro.message);
            this.spinnerService.hideSpinner();

            return this.router.navigate(["/login"]);
          }
        );
      }
      else {

        this.auth = JSON.parse(
          '{"' +
          atob(this.param)
            .replace(/[ }{"]/gi, "")
            .replace(/:/gi, '":"')
            .replace(/,/gi, '","') +
          '"}'
        );

        if (
          this.auth.hasOwnProperty("beneficiario") &&
          this.auth.beneficiario === "null"
        ) {
          this.messageService.showError(
            "Não foi possível identificar o beneficiário."
          );
          return this.router.navigate(["/login"]);
        }

        switch (this.auth.tipo) {
          case Role.User:
            this.authService.loginAuditor(this.auth.usuario, 1).subscribe(
              (usuarioCompleto: any) => {
                usuarioCompleto = {
                  ...usuarioCompleto,
                  ...{
                    usuarioId: this.auth.usuario,
                  },
                };

                this.authService.autenticacaoRapida(usuarioCompleto, this.auth);

              },
              (erro) => {
                this.messageService.showError(erro.message);
                this.spinnerService.hideSpinner();

                return this.router.navigate(["/login"]);
              }
            );
            break;
          case Role.Doctor:
            this.authService.loginAuditor(this.auth.usuario, 2).subscribe(
              (usuarioCompleto: any) => {
                usuarioCompleto = {
                  ...usuarioCompleto,
                  ...{
                    usuarioId: this.auth.usuario,
                  },
                };

                this.authService.autenticacaoRapida(usuarioCompleto, this.auth);

              },
              (erro) => {
                this.messageService.showError(erro.message);
                this.spinnerService.hideSpinner();

                return this.router.navigate(["/login"]);
              }
            );
            break;
          case Role.Auditor:
            this.authService.loginAuditor(this.auth.usuario, 3).subscribe(
              (usuarioCompleto: any) => {
                usuarioCompleto = {
                  ...usuarioCompleto,
                  ...{
                    usuarioId: this.auth.usuario,
                  },
                };
                this.authService.autenticacaoRapida(usuarioCompleto, this.auth);
              },
              (erro) => {
                this.messageService.showError(erro.message);
                this.spinnerService.hideSpinner();

                return this.router.navigate(["/login"]);
              }
            );
            break;
          case Role.MKPREV:
            this.authService.loginAuditor(this.auth.usuario, 4).subscribe(
              (usuarioCompleto: any) => {
                usuarioCompleto = {
                  ...usuarioCompleto,
                  ...{
                    usuarioId: this.auth.usuario,
                  },
                };
                this.authService.autenticacaoRapida(usuarioCompleto, this.auth);
              },
              (erro) => {
                this.messageService.showError(erro.message);
                this.spinnerService.hideSpinner();

                return this.router.navigate(["/login"]);
              }
            );
            break;
          case Role.MKMAMA:
            this.authService.loginAuditor(this.auth.usuario, 5).subscribe(
              (usuarioCompleto: any) => {
                usuarioCompleto = {
                  ...usuarioCompleto,
                  ...{
                    usuarioId: this.auth.usuario,
                  },
                };
                this.authService.autenticacaoRapida(usuarioCompleto, this.auth);
              },
              (erro) => {
                this.messageService.showError(erro.message);
                this.spinnerService.hideSpinner();

                return this.router.navigate(["/login"]);
              }
            );
            break;
          case Role.MkMedico:
            this.authService.loginAuditor(this.auth.usuario, 6).subscribe(
              (usuarioCompleto: any) => {
                usuarioCompleto = {
                  ...usuarioCompleto,
                  ...{
                    usuarioId: this.auth.usuario,
                  },
                };
                this.authService.autenticacaoRapida(usuarioCompleto, this.auth);
              },
              (erro) => {
                this.messageService.showError(erro.message);
                this.spinnerService.hideSpinner();

                return this.router.navigate(["/login"]);
              }
            );
            break;
          case Role.Token:
            this.authService
              .loginBenEncoded(this.auth.usuario?.toString())
              .subscribe(
                (u: any) => {
                  u = {
                    ...u,
                    ...{
                      role: this.auth.tipo,
                      areaRestrita: { handle: +this.auth.handle },
                    },
                  };
                  this.authService.authenticate(u);
                },
                (erro) => {
                  this.messageService.showError(
                    erro.hasOwnProperty("message")
                      ? this.textoService.Erro + erro.message
                      : this.textoService.ErroAoExecutarSolicitacao + erro.name
                  );
                  this.spinnerService.hideSpinner();
                  return this.router.navigate(["/login"]);
                }
              );
            break;

          default:
            setTimeout(() => this.spinnerService.hideSpinner(), 0);
            this.router.navigate(["/login"]);
            break;
        }
      }
    } catch (error) {
      this.spinnerService.hideSpinner();
      this.router.navigate(["/login"]);
    }
  }

  formataCarteirinha(carteirinha: string): string {
    return carteirinha.startsWith("00") ? carteirinha : `0${carteirinha}`;
  }
}
