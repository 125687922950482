import {
  Beneficiario,
  BeneficiarioFormatado,
} from "src/app/types/Beneficiario";
import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { Observable, of } from "rxjs";
import { HelperService } from "../../../services/helper.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
import { EventEmitterService } from "src/app/services/event-emitter.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-lista-ultimos-atendimentos",
  templateUrl: "./lista-ultimos-atendimentos.component.html",
  styleUrls: ["./lista-ultimos-atendimentos.component.scss"],
})
export class ListaUltimosAtendimentosComponent implements AfterViewInit {
  @Input() ultimosAtendimentos$: Observable<BeneficiarioFormatado[]> = of([]);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pep = {
    red: "Visualizar",
    orange: "Visualizar",
    green: "Visualizar",
  };
  lista: BeneficiarioFormatado[];
  ultimosAtendimentosDataSource: MatTableDataSource<Beneficiario>;
  pageLength: number;
  pageIndex: number;
  paginatorStatus: {
    pageIndex: number;
    pageSize: number;
  };


  dataIntervalo: string;
  beforeDate: Date;

  displayedColumns = [
    "nome",
    "carteirinha",
    "nascimento",
    "dataAutorizacao",
    "pep",
  ];
  filtrarDias: any[] = [
    ["Hoje", 1],
    ["7 dias", 7],
    ["30 dias", 31],
  ];
  checkedDias: number;

  constructor(private router: Router) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ultimosAtendimentos$.subscribe((x) => this.sortedList(x));
      this.checkedDias = !!window.sessionStorage.getItem("filtroDias")
        ? Number(window.sessionStorage.getItem("filtroDias"))
        : this.filtrarDias[0][1];
      this.paginatorStatus = JSON.parse(
        window.sessionStorage.getItem("paginatorStatus")
      );
    }, 50);
  }

  /**
   * Define, Filtra e ordena o DataSource a partir da lista de beneficiários em atendimento.
   * @param {Beneficiario[]} autorizacoes
   */
  private sortedList(beneficiarios: Beneficiario[]): void {
    this.lista = beneficiarios.map((a: BeneficiarioFormatado) => {
      a.beneficiarioCarteirinha = HelperService.formataCarteirinha(
        a.carteirinha
      );
      a.beneficiarioDataNascimento = HelperService.stringToDate(
        a.dataNascimento
      ).toLocaleDateString();
      return a;
    });

    this.pageLength = this.lista.length;
    this.ultimosAtendimentosDataSource = new MatTableDataSource(this.lista);
    this.ultimosAtendimentosDataSource.sort = this.sort;
    this.ultimosAtendimentosDataSource.sortingDataAccessor = (
      item,
      property
    ) => {
      switch (property) {
        case "nome":
          return item.beneficiarioNome.toUpperCase();
        case "dataAutorizacao":
          return new Date(item.dataAtendimento) || "";
        case "carteirinha":
          return item.carteirinha;
        case "nascimento":
          return item.dataNascimento;
        case "pep":
          return item.situacaoPep;
        default:
          return item[property];
      }
    };
    this.ultimosAtendimentosDataSource.filterPredicate = (
      order,
      filter: string
    ) => {
      const transformedFilter = filter.trim().toLowerCase();
      const listAsFlatString = (obj): string => {
        let returnVal = "";
        Object.values(obj).forEach((val) => {
          if (typeof val !== "object") {
            returnVal = returnVal + val;
          } else if (val !== null) {
            returnVal = returnVal + listAsFlatString(val);
          }
        });
        return returnVal.trim().toLowerCase();
      };

      return listAsFlatString(order).includes(transformedFilter);
    };

    if (!!this.paginatorStatus) {
      this.paginator.pageIndex = this.paginatorStatus.pageIndex;
      this.paginator.page.next({
        pageIndex: this.paginatorStatus.pageIndex,
        pageSize: this.paginatorStatus.pageSize,
        length: this.lista.length,
      });
    }
    this.ultimosAtendimentosDataSource.paginator = this.paginator;
  }

  /**
   * Filtra o DataSource de acordo com o que for digitado
   * @param filterValue
   */
  applyFilter(filterValue: string): void {
    this.ultimosAtendimentosDataSource.filter = filterValue
      .trim()
      .toLowerCase();
  }

  applyDataFilter(value: any) {
    const hoje = moment();
    this.beforeDate = hoje.subtract(value, "days").toDate();
    window.sessionStorage.setItem("filtroDias", value);
    EventEmitterService.get("QuantidadesDeDiasAnteriores").emit(value)

    this.ultimosAtendimentosDataSource.data = this.lista;
    this.ultimosAtendimentosDataSource.data =
      this.ultimosAtendimentosDataSource.data.filter(
        (item: BeneficiarioFormatado) =>
          moment(item.dataAtendimento).toDate() >= this.beforeDate
      );
      this.pageLength = this.ultimosAtendimentosDataSource.data.length;
  }
  /**
   * Recebe o valor do checkbox e emite para filtrar o período a ser exibido
   * @param value
   */


  getPaginatorEvent(event: PageEvent) {
    const obj = {
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    };
    window.sessionStorage.setItem("paginatorStatus", JSON.stringify(obj));
  }

  navegarDetalhes(beneficiario: Beneficiario){
    localStorage.setItem("handleBenef", beneficiario.beneficiarioId.toString());
    this.router.navigate(["/detalhes"]);
  }
}
