import { Component, OnInit } from "@angular/core";
import { speedDialFabAnimations } from "./menu-fab.animations";
import { AuthenticationService } from "src/app/services/authentication.service";
import { NavbarService } from "src/app/services/navbar.service";
import { DialogModalComponent } from "../dialog-modal/dialog-modal.component";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { TextoService } from "src/app/services/texto.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-menu-fab",
  templateUrl: "./menu-fab.component.html",
  styleUrls: ["./menu-fab.component.scss"],
  animations: speedDialFabAnimations,
})
export class MenuFabComponent implements OnInit {
  fabButtons = [
    {
      icon: "exit_to_app",
      id: "exit",
      route: "",
      class: "warn",
      function: "openDialog",
      tip: "Sair - Encerrar sessão",
    },
    {
      icon: "import_contacts",
      id: "",
      route: "ajuda",
      class: "",
      function: "navegarPara",
      tip: "Ir para a página de ajuda",
    },
    {
      icon: "assignment_ind",
      id: "",
      route: "atendimento",
      class: "",
      function: "navegarPara",
      tip: "Últimos atendimentos",
    },
    {
      icon: "home",
      id: "",
      route: "/",
      class: "",
      function: "navegarPara",
      tip: "Página Inicial",
    },
  ];
  buttons = [];
  fabToggleState = "inactive";
  modalDialogRef: MatDialogRef<DialogModalComponent>;

  constructor(
    private textoService: TextoService,
    public authenticationService: AuthenticationService,
    public navbarService: NavbarService,
    private dialogService: MatDialog,
    public router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {}

  showItems() {
    this.fabToggleState = "active";
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabToggleState = "inactive";
    this.buttons = [];
  }

  private ToggleFab() {
    this.fabToggleState === "active" ? this.hideItems() : this.showItems();
  }

  navegarPara(rota: string) {
    this.router.navigate([rota]);
  }

  onMouseToggleFab() {
    if (this.deviceService.isDesktop()) {
      this.ToggleFab();
    }
  }

  onClickToggleFab() {
    if (!this.deviceService.isDesktop()) {
      this.ToggleFab();
    }
  }

  openDialog() {
    this.modalDialogRef = this.dialogService.open(DialogModalComponent, {
      data: {
        tipo: "logout",
        titulo: "",
        mensagem: this.textoService.DesejaEncerrarSessao,
      },
    });
  }

  voltarPagina() {
    if (
      !window.document.querySelectorAll(".shepherd-modal-is-visible").length
    ) {
      window.history.back();
    }
  }

  buttonAction(route: any, action: any) {
    switch (action) {
      case "openDialog":
        return this.openDialog();
      case "navegarPara":
        return this.navegarPara(route);
      default:
        break;
    }
  }
}
