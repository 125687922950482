import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
import { HelperService } from "src/app/services/helper.service";
import { MessageService } from "src/app/services/message.service";
import { NavbarService } from "src/app/services/navbar.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { TextoService } from "src/app/services/texto.service";
import { TipoLogin } from "src/app/types/TipoLogin";
import { User } from "src/app/types/User";

@Component({
  selector: "app-login",
  host: { class: "full-width my-auto" },
  templateUrl: "./page-login.component.html",
  styleUrls: ["./page-login.component.scss"],
})
export class PageLoginComponent implements OnInit {
  @ViewChild("username") inputEl: ElementRef;

  tipoLogin: TipoLogin[];
  loginForm: FormGroup;
  lockIcon = "lock_outline";
  disableBtn = true;
  errors: any;
  usuario: User;

  constructor(
    private textoService: TextoService,
    private spinnerService: SpinnerService,
    private builder: FormBuilder,
    private navbarService: NavbarService,
    private helperService: HelperService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService
  ) {
    this.loginForm = this.builder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      tipo: [null, Validators.required],
    });

    this.tipoLogin = TipoLogin.list();
  }

  ngOnInit() {
    setTimeout(() => {
      this.loginForm.get("tipo").setValue(TipoLogin.coop.value);
      this.inputEl?.nativeElement?.focus();
      this.navbarService.hide();
      this.helperService.setClassePagina("login-page");
    }, 0);
    this.loginForm.valueChanges.subscribe(() => {
      if (this.loginForm.valid) {
        this.lockIcon = "lock_open";
        this.disableBtn = false;
      } else {
        this.lockIcon = "lock_outline";
        this.disableBtn = true;
      }
    });
  }

  loginSubmit() {
    this.spinnerService.showSpinner();
    this.disableBtn = true;

    if (this.loginForm.invalid) {
      this.spinnerService.hideSpinner();
      return false;
    }

    this.authenticationService
      .login(
        this.loginForm.controls.username.value,
        this.loginForm.controls.password.value,
        TipoLogin[this.loginForm.controls.tipo.value].key
      )
      .subscribe(
        (u: any) => {
          this.authenticationService.authenticate(u);
          this.spinnerService.hideSpinner();
        },
        (erro: any) =>
          this.messageService.showError(
            erro.hasOwnProperty("message")
              ? this.textoService.Erro + erro.message
              : this.textoService.ErroAoExecutarSolicitacao + erro.name
          )
      )
      .add(() => this.spinnerService.hideSpinner());
  }
}
