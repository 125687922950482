import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-erro404',
  templateUrl: './page-erro404.component.html',
  styleUrls: [ './page-erro404.component.scss' ],
  host: { class: 'full-width my-auto' },
})
export class PageErro404Component implements OnInit {
  pageClass = 'page-404';

  public windowWidth = window.screen.width;

  constructor(private helperService: HelperService) {
  }

  ngOnInit() {
    setTimeout(() => this.helperService.setClassePagina(this.pageClass), 0);
  }



}
