import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';

import {AppComponent} from './app.component';
import {AppMaterialModule} from './modules/app-material/app-material.module';
import {AppRoutingModule} from './modules/app-routing/app-routing.module';
import {ArraySortPipe} from './types/ArraySortPipe';
import {AvatarComponent} from './components/layout/avatar/avatar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie-service';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {DialogModalComponent} from './components/ui/dialog-modal/dialog-modal.component';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FooterComponent} from './components/layout/footer/footer.component';
import {HttpsRequestInterceptor} from './interceptors/https.interceptor';
import {ListaBuscaBeneficiariosComponent} from './components/layout/lista-busca-beneficiarios/lista-busca-beneficiarios.component';
import {ListaExamesBeneficiarioComponent} from './components/layout/lista-exames-beneficiario/lista-exames-beneficiario.component';
import {ListaUltimosAtendimentosComponent} from './components/layout/lista-ultimos-atendimentos/lista-ultimos-atendimentos.component';
import { LogoComponent } from './components/layout/logo/logo.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MenuFabComponent} from './components/ui/menu-fab/menu-fab.component';
import {MenuPrincipalComponent} from './components/layout/menu-principal/menu-principal.component';
import {MomentModule} from 'ngx-moment';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {NgxMaskModule} from 'ngx-mask';
import {NgxPaginationModule} from 'ngx-pagination';
import {PageAceiteConfidencialidadeComponent} from './components/pages/page-aceite-confidencialidade/page-aceite-confidencialidade.component';
import {PageAjudaComponent} from './components/pages/page-ajuda/page-ajuda.component';
import {PageAtendimentoComponent} from './components/pages/page-atendimento/page-atendimento.component';
import { PageAutenticadorComponent } from './components/pages/page-autenticador/page-autenticador.component';
import {PageDetalhesComponent} from './components/pages/page-detalhes/page-detalhes.component';
import {PageErro404Component} from './components/pages/page-erro404/page-erro404.component';
import {PageLoginComponent} from './components/pages/page-login/page-login.component';
import {PageTrocarSenhaComponent} from './components/pages/page-trocar-senha/page-trocar-senha.component';
import {PageUploadTermosComponent} from './components/pages/page-upload-termos/page-upload-termos.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ProgressSpinnerDialogComponent} from './components/ui/progress-spinner-dialog/progress-spinner-dialog.component';
import { SafePipePipe } from './safe-pipe.pipe';
import {SpinnerComponent} from './components/ui/spinner/spinner.component';
import { VisualizarPdfComponent } from './components/ui/visualizar-pdf/visualizar-pdf.component';
import {getPbPaginatorIntl} from './languages/pb-paginator-intl';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import ptBr from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment.prod';

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    ArraySortPipe,
    MenuPrincipalComponent,
    FooterComponent,
    SpinnerComponent,
    MenuFabComponent,
    DialogModalComponent,
    PageLoginComponent,
    PageAtendimentoComponent,
    PageDetalhesComponent,
    PageErro404Component,
    PageAceiteConfidencialidadeComponent,
    PageUploadTermosComponent,
    ListaBuscaBeneficiariosComponent,
    ListaExamesBeneficiarioComponent,
    AvatarComponent,
    ProgressSpinnerDialogComponent,
    PageTrocarSenhaComponent,
    LogoComponent,
    PageAjudaComponent,
    ListaUltimosAtendimentosComponent,
    PageAutenticadorComponent,
    VisualizarPdfComponent,
    SafePipePipe,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    MomentModule,
    NgxMaskModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    FlexLayoutModule,
    AppMaterialModule,
    AppRoutingModule,
    MatTableModule,
    MatExpansionModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    PdfViewerModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getPbPaginatorIntl() },
    { provide: LOCALE_ID, useValue: "pt" },
    CookieService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogModalComponent, ProgressSpinnerDialogComponent],
})
export class AppModule {}

