import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../types/User';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  usuario: User;

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser$.subscribe(x => this.usuario = x);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const Authorization = this.authenticationService.currentUserValue ? `Bearer ${this.authenticationService.currentUserValue.token}` : '';
    request = request.clone({
      setHeaders: {
        Authorization,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
    });

    return next.handle(request);
  }
}

