import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-modal",
  templateUrl: "./dialog-modal.component.html",
})
export class DialogModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogModalComponent>,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {}

  logout() {
    this.authenticationService.logout().subscribe((x) => {
      this.dialogRef.close();
      this.router.navigate(["/login"]);
    });
  }

  printTermo(handle: any) {
    this.dialogRef.close();
    this.router.navigate(["enviar-termo", handle]);
  }
}
