import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  public classePagina: string;
  public networkError: boolean;

  static formataCarteirinha(numero: string) {
    return numero.replace(
      /(\d{4})(\d{4})(\d{6})(\d{2})(\d)/g,
      "$1.$2.$3.$4" + (numero.length > 16 ? "-" : "") + "$5"
    );
  }

  static stringToDate(data: string) {
    const xSplit = data.split("-");

    return new Date(
      Number(xSplit[0]),
      Number(xSplit[1]) - 1,
      Number(xSplit[2]),
      0,
      0,
      0
    );
  }

  /**
   * Remove objetos duplicados de um array de objetos segundo uma propriedade e retorna um novo array sem duplicados.
   * @param {any[]} lista - Array de objetos.
   * @param {string} propriedade - O nome da propriedade ao qual queremos buscar por duplicados no Array.
   */
  static removeDuplicadosPropObjeto(lista: any[], propriedade: string): any[] {
    return Object.values(
      lista.reduce(
        (acc, cur) => Object.assign(acc, { [cur[propriedade]]: cur }),
        {}
      )
    );
  }

  static timeISOLocal() {
    return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1);
  }

  static getYesterdaysDate(dias: number = 1) {
    const date = new Date();
    date.setDate(date.getDate() - Math.abs(dias));
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  static padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.classePagina = "";
    this.networkError = false;
  }

  removerAcentos(texto: any) {
    if (typeof texto === "string") {
      // return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      return texto
        .toLowerCase()
        .replace(new RegExp("[ÁÀÂÃ]", "gi"), "a")
        .replace(new RegExp("[ÉÈÊ]", "gi"), "e")
        .replace(new RegExp("[ÍÌÎ]", "gi"), "i")
        .replace(new RegExp("[ÓÒÔÕ]", "gi"), "o")
        .replace(new RegExp("[ÚÙÛ]", "gi"), "u")
        .replace(new RegExp("[Ç]", "gi"), "c");
    }
    return texto;
  }

  setNetworkError(error: boolean) {
    this.networkError = error;
  }

  setClassePagina(classe: string) {
    this.classePagina = classe;
  }

  compare(
    a: number | string,
    b: number | string,
    isAsc: boolean,
    isDate = false
  ) {
    if (isDate) {
      return (
        (HelperService.stringToDate(a.toString()) <
          HelperService.stringToDate(b.toString())
          ? -1
          : 1) * (isAsc ? 1 : -1)
      );
    }

    return (
      (this.removerAcentos(a) < this.removerAcentos(b) ? -1 : 1) *
      (isAsc ? 1 : -1)
    );
  }

  base64ToPDF(data: string) {
    return `<object width="100%" height="100%" data="data:application/pdf;base64, ${data}" type="application/pdf" class="internal">
            <embed src="data:application/pdf;base64, ${data}" type="application/pdf">
          </object>`;
  }

  convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result);

      reader.readAsDataURL(blob);
    });
  }

  formataCPF(cpf: string): string {
    const cpfAtual = cpf;

    let cpfAtualizado;

    cpfAtualizado = cpfAtual.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      function (regex, argumento1, argumento2, argumento3, argumento4) {
        return (
          argumento1 + "." + argumento2 + "." + argumento3 + "-" + argumento4
        );
      }
    );
    return cpfAtualizado;
  }

  
}
