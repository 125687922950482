<footer class="container">
  <div class="row">
    <div class="col s12 center-align">
      <a
        href="https://www.ans.gov.br"
        target="_blank"
        class="registro-ans"
        rel="noreferrer"
      >
        <img
          src="assets/imagens/unimed-ans-nova.jpg"
          matTooltip="Unimed Presidente Prudente - ANS nº - 31.579-6"
          alt="Registro ANS nº - 31.579-6"
        />
      </a>
      <p>&copy; 2022 - Unimed Presidente Prudente</p>
      <p>
        Av. Cel. José Soares Marcondes, 3355 - Fone:
        <a href="tel:+551821018311" title="Telefone">(18) 2101-8311</a>
      </p>
      <small>v{{ version }}</small>
    </div>
  </div>
</footer>
