export enum Role {
  User = 'User',
  Doctor = 'Doctor',
  Auditor = 'Auditor',
  MkMedico = 'MKMEDICO',
  Prestador = 'Prestador',
  MKPREV = 'MKPREV',
  Token = 'Token',
  MKMAMA = 'MKMAMA',
}
