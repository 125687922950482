export class TipoLogin {
  static readonly ben = new TipoLogin(1, "ben", "Beneficiario");
  static readonly coop = new TipoLogin(2, "coop", "Cooperado");
  static readonly auditor = new TipoLogin(3, "aud", "Auditor");
  static readonly mkPrev = new TipoLogin(4, "mkPrev", "MkPrev");
  static readonly mkMama = new TipoLogin(5, "mkMama", "MkMama");
  static readonly mkMed = new TipoLogin(6, "mkMed", "MkMedico");

  // private to disallow creating other instances of this type
  private constructor(
    public key: number,
    public readonly value: any,
    public viewValue: string
  ) {}

  static list(): TipoLogin[] {
    return [this.coop, this.ben];
  }
}
