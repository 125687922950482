import { Component, OnInit } from "@angular/core";
import { SpinnerService } from "src/app/services/spinner.service";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
})
export class SpinnerComponent implements OnInit {
  constructor(public spinner: SpinnerService) {}

  ngOnInit() {}
}
