import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  public loading: boolean;

  constructor() {
    this.loading = false;
  }

  showSpinner() {
    this.loading = true;
  }

  hideSpinner() {
    this.loading = false;
  }
}
