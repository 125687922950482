<a
  [routerLink]="['/atendimento']"
  class="main-logo"
  matTooltip="Unimed Presidente Prudente"
>
  <img
    src="assets/imagens/logo-banco-exames-header.svg"
    alt="Unimed Presidente Prudente"
  />
</a>
