import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { HelperService } from './services/helper.service';
import { NavbarService } from './services/navbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Banco de exames - Unimed Presidente Prudente';


  constructor(
    public authenticationService: AuthenticationService,
    public navbarService: NavbarService,
    public helperService: HelperService,
    public router: Router,
  ) {
    this.authenticationService.currentUser$.subscribe(() => this.navbarService.show());
  }
}
