<app-spinner></app-spinner>

<div class="container">
  <div class="nav nav-tabs custom-tab">
    <ul>
      <li class="nav-item nav-link active" data-bs-toggle="tab" href="#tab1" #tabLaudosDisponiveis>
        <img src="assets/imagens/icons/laudos-disponiveis-icon.svg" alt="icone laudos disponíveis" />
        <a>Laudos Disponíveis</a>
      </li>

      <li class="nav-item nav-link" data-bs-toggle="tab" href="#tab2">
        <img src="assets/imagens/icons/historico-exames-icon.svg" alt="icone histórico de exames" />
        <a>Histórico de Exames</a>
      </li>
    </ul>

    <div class="filtro-container">
      <mat-form-field class="form-field full-width custom-filtro" appearance="outline">
        <label class="sr-only" for="search">Filtrar exames</label>
        <input #search matInput id="search" name="search" autocomplete="off" placeholder="Filtrar exames"
          [(ngModel)]="filtro" (keyup)="filtrarArray()" />

        <button id="search" style="color: #b1d34b" mat-button *ngIf="search.value.length > 0 == false" matSuffix
          mat-icon-button aria-label="Buscar" (click)="filtrarArray()">
          <mat-icon>search</mat-icon>
        </button>

        <button id="fechar" style="color: #b1d34b" mat-button *ngIf="search.value.length > 0" matSuffix mat-icon-button
          aria-label="Clear" (click)="applyFilter('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="tab-content">
    <div class="tab-pane fade show active" id="tab1">
      <ng-container *ngIf="deviceService.isDesktop()">
        <mat-accordion *ngFor="let item of listaDeResultadosFiltrado">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header style="margin-top: 12px">
              <label for="" class="d-flex flex-column" style="min-width: 10%;">
                <strong>Data:</strong>
                <span>{{ item.dataResultado | date : "dd/MM/yyyy" }}</span>
              </label>
              <label for="" class="d-flex flex-column" style="width: 70%">
                <strong>Prestador:</strong>
                <span>{{ item.prestadorCodigo }} - {{ item.prestadorNome }}</span>
              </label>

              <div></div>
            </mat-expansion-panel-header>
            <mat-panel-description style="cursor: pointer">
              <ng-container *ngFor="let evento of item.exames">
                <div class="d-flex align-items-center justify-content-between" (click)="abrirLaudo(evento.documentoId)">
                  <label for="" class="d-flex flex-column pb-0" style="margin-left: 7px; min-width: 10%;">
                    <small>Exame</small>
                    <span>{{ evento.eventoCodigo }}</span>
                  </label>
                  <label for="" class="d-flex flex-column pb-0" style="width: 67.5%">
                    <small>Exame Descrição</small>
                    <span>{{ evento.eventoNome }}</span>
                  </label>
                  <button class="eye-button" style="margin-right: 5px">
                    <img src="assets/imagens/icons/page-detalhes/eye.svg" alt="" />
                  </button>
                </div>
              </ng-container>
            </mat-panel-description>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>

      <ng-container *ngIf="deviceService.isMobile()">
        <mat-accordion *ngFor="let item of listaDeResultadosFiltrado">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header style="height: 100% !important; margin-top: 5px">
              <mat-panel-title>
                <div class="d-flex flex-column">
                  <header class="d-flex align-items-center justify-content-between w-100">
                    <div class="d-flex flex-column">
                      <strong>Data:</strong>
                      <span>{{
                        item.dataResultado | date : "dd/MM/yyyy"
                        }}</span>
                    </div>
                  </header>
                  <section class="d-flex flex-column">
                    <strong>Prestador:</strong>
                    <p class="text-justify">
                      {{ item.prestadorCodigo }} - {{ item.prestadorNome }}
                    </p>
                  </section>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex flex-column w-100">
              <ng-container *ngFor="let evento of item.exames" style="font-size: 12px; width: 100%">
                <div class="p-2 d-flex flex-column w-100 border-bottom" style="margin-left: 7px"
                  (click)="abrirLaudo(evento.documentoId)">
                  <div class="d-flex w-100 justify-content-between align-items-center">
                    <div class="d-flex flex-column mb-2 w-100">
                      <small>Exame</small>
                      <span>{{ evento.eventoCodigo }}</span>
                    </div>
                    <div class="d-flex">
                      <button class="eye-button" style="margin-right: 14px">
                        <img src="assets/imagens/icons/page-detalhes/eye.svg" alt="" />
                      </button>
                    </div>
                  </div>

                  <div class="d-flex flex-column">
                    <small>Exame Descrição</small>
                    <span>{{ evento.eventoNome }}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>

      <div class="no-data pt-5" *ngIf="listaDeResultadosFiltrado?.length > 0 == false">
        <p class="text-center p-2">Não foi encontrado laudos disponíveis.</p>
      </div>
    </div>
    <div class="tab-pane fade" id="tab2">
      <div class="">
        <table matSort (matSortChange)="sortData($event)" class="custom-table">
          <tr matColumnDef="autorizacao" class="custom-column-table">
            <th mat-sort-header="autorizacao" class="custom-th-historico-desktop td-center" style="min-width: 100px;">
              Autorização
            </th>
            <!-- <th
              mat-sort-header="dataAutorizacao"
              class="custom-th-historico-desktop td-center"
            >
              Data Solicitação
            </th> -->
            <th mat-sort-header="dataExecucao" class="custom-th-historico-desktop td-center">
              Data Execução
            </th>

            <th mat-sort-header="exame" class="custom-th-historico-desktop td-center" style="min-width: 100px;">
              Exame
            </th>
            <th mat-sort-header="exameDescricao" class="custom-th-historico-desktop">
              Exame Descrição
            </th>

            <th mat-sort-header="prestador" class="custom-th-historico-desktop">
              Prestador
            </th>

            <th class="custom-th-historico-desktop td-center" style="min-width: 50px;">
              Laudo
            </th>
          </tr>
          <tr class="mat-row" *ngFor="let dado of listaResultadoHistoricoExamesFiltrado"
            (click)="filtrarLaudosDisponiveis(dado)">
            <td class="custom-td-historico-desktop td-center" style="min-width: 100px;">
              {{ dado.numeroAutorizacao }}
            </td>
            <!-- <td class="custom-td-historico-desktop td-center">
              {{ dado.dataHoraSolicitacao | date : "dd/MM/yyyy" }}
            </td> -->
            <td class="custom-td-historico-desktop td-center">
              {{ dado.dataHoraExecucao | date : "dd/MM/yyyy" }}
            </td>

            <td class="custom-td-historico-desktop td-center" style="min-width: 100px;">
              {{ dado.eventoEstrutura }}
            </td>

            <td class="custom-td-historico-desktop">
              {{ dado.eventoDescricao }}
            </td>
            <td class="custom-td-historico-desktop">
              {{ dado.executorNome }}
            </td>
            <td class="custom-td-historico-desktop td-center" style="min-width: 50px;">
              <img src="assets/imagens/icons/filter.svg" (click)="filtrarLaudosDisponiveis(dado)" />
            </td>
          </tr>
        </table>

        <div class="no-data pt-5" *ngIf="listaResultadoHistoricoExamesFiltrado?.length > 0 == false">
          <p class="text-center p-2">Não foi encontrado laudos disponíveis.</p>
        </div>

        <section class="custom-historico-mobile" *ngFor="let dado of listaResultadoHistoricoExamesFiltrado">
          <div class="custom-historico-card">
            <div class="custom-historico-card-bloco" (click)="filtrarLaudosDisponiveis(dado)">
              <img src="assets/imagens/icons/icon-exame-card-mobile.svg" />
              <div class="custom-historico-card-celula justify-content-between">
                <p class="custom-title-historico">Exame</p>
                <div>
                  <p>{{ dado.eventoEstrutura }}</p>
                  <p>{{ dado.eventoDescricao }}</p>
                </div>
              </div>
              <img src="assets/imagens/icons/filter.svg" />
            </div>
            <!-- <div class="custom-historico-card-bloco">
              <img
                src="assets/imagens/icons/icon-date-card-mobile.svg"
              />
              <div class="custom-historico-card-celula">
                <p class="custom-title-historico">Data Solicitação</p>
                <p>{{ dado.dataHoraSolicitacao | date : "dd/MM/yyyy" }}</p>
              </div>
            </div> -->

            <div class="custom-historico-card-bloco">
              <div class="custom-historico-card-bloco">
                <img src="assets/imagens/icons/icon-autorizacao-card-mobile.svg" />
                <div class="custom-historico-card-celula">
                  <p class="custom-title-historico">Autorização</p>
                  <p>{{ dado.numeroAutorizacao }}</p>
                </div>
              </div>

              <div class="custom-historico-card-bloco">
                <img src="assets/imagens/icons/icon-date-card-mobile.svg" />
                <div class="custom-historico-card-celula">
                  <p class="custom-title-historico">Data Execução</p>
                  <p>{{ dado.dataHoraExecucao | date : "dd/MM/yyyy" }}</p>
                </div>
              </div>
            </div>

            <div class="custom-historico-card-bloco">
              <img src="assets/imagens/icons/icon-prestador-card-mobile.svg" />
              <div class="custom-historico-card-celula">
                <p class="custom-title-historico">Prestador</p>
                <p>{{ dado.executorNome }}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>